$header-height: 46px !default;
$mobileSharingToolsHeight: 47px;
$headerPlusSharingToolsHeight: $header-height + $mobileSharingToolsHeight;

@mixin iconBack($icon) {
  background-size: contain;
  background-position: center;
  background-image: url('./assets/#{$icon}.svg');
  background-repeat: no-repeat;
}

.new-mobile-article-design {
  .mobile-sharing {
    .mobile-sharing-tools {
      display: none;
    }

    &.mobile-sharing-activated {
      height: $header-height;

      .mobile-sharing-button {
        width: 46px;
        height: 46px;
        position: relative;
        background: none;

        &::before {
          @include iconBack('close');

          display: inline-block;
          width: 27px;
          height: 22px;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .mobile-sharing-tools {
        display: block;
      }

      & ~ .scrollable-content {
        padding-top: $header-height !important;
      }
    }

    & ~ .scrollable-content {
      padding-top: $header-height !important;
    }
  }

  @media screen and (max-width: 360px) {
    header.mobile-sharing {
      height: $header-height;

      .mobile-sharing-button {
        display: none;
      }

      .mobile-sharing-tools {
        display: block; // on small devices, the share tools buttons are hidden
        .social {
          &.masthead .facebook .facebook-long-dark {
            @include iconBack('facebookDark');

            width: 30px;
          }

          &.masthead .twitter .twitter-long-dark {
            @include iconBack('twitterDark');

            width: 30px;
          }
        }
      }
    }

    .social.aboverelatedarticles {
      a.facebook-long {
        @include iconBack('facebookLight');

        width: 30px;
      }

      a.twitter-long {
        @include iconBack('twitterDark');

        width: 30px;
      }
    }

    .mobile-sharing ~ .scrollable-content {
      padding-top: $header-height !important;
    }

    .isHidden #mobile-content header.mobile-sharing {
      transform: translate3d(0, -$header-height, 0) !important;
    }
  }

  .isHidden #mobile-content header.mobile-sharing-activated {
    transform: translate3d(0, -$header-height, 0) !important;
  }
}

.mobile-sharing {
  .mobile-sharing-button {
    @include iconBack('atom');
  }

  .mobile-sharing-tools {
    display: none; // this sharing tools bar will be displayed on demand (by clicking on .mobile-sharing-button )
    height: $mobileSharingToolsHeight;
    width: 100%;
    background-color: #e9e9e9;

    .social {
      margin: 0 auto;
      height: 100%;

      & > span {
        display: inline-block;
        float: left;
        margin-top: 13px;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.5px;
        color: #000;
        padding-left: 10px;
      }

      ul {
        background-color: transparent;
        float: right;
        padding-right: 10px;
        padding-left: 10px;
        border: 0;
        width: 100%;

        li {
          border: 0;
          height: initial;
          padding: 0;
        }
      }
    }
  }

  &.mobile-sharing-activated {
    height: $headerPlusSharingToolsHeight;

    .mobile-sharing-button {
      width: 46px;
      height: 46px;
      position: relative;
      background: none;

      &::before {
        @include iconBack('close');

        display: inline-block;
        width: 27px;
        height: 22px;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .mobile-sharing-tools {
      display: block;
    }

    & ~ .scrollable-content {
      padding-top: $headerPlusSharingToolsHeight !important;
    }
  }
}

header.mobile-sharing ul {
  float: left;

  &.sharing-buttons {
    float: right;
    height: 46px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.mobile-sharing ~ .scrollable-content {
  padding-top: $header-height;
}

@media screen and (max-width: 360px) {
  header.mobile-sharing {
    height: $headerPlusSharingToolsHeight;

    .mobile-sharing-button {
      display: none;
    }

    .mobile-sharing-tools {
      display: block; // on small devices, the share tools buttons are hidden
      .social {
        &.masthead .facebook .facebook-long-dark {
          @include iconBack('facebookDark');

          width: 30px;
        }

        &.masthead .twitter .twitter-long-dark {
          @include iconBack('share-twitter');

          width: 30px;
        }
      }
    }
  }

  .social.aboverelatedarticles {
    a.facebook-long {
      @include iconBack('facebookLight');

      width: 30px;
    }

    a.twitter-long {
      @include iconBack('share-twitter');

      width: 30px;
    }
  }

  .mobile-sharing ~ .scrollable-content {
    padding-top: $headerPlusSharingToolsHeight !important;
  }

  .isHidden #mobile-content header.mobile-sharing {
    transform: translate3d(0, -$headerPlusSharingToolsHeight, 0) !important;
  }
}

@media screen and (min-width: 360px) { // Displaying a wider facebook icon on wider devices
  .mobile-sharing-tools {
    .social {
      span {
        font-size: 15px;
        padding-right: 0;
      }

      .facebook a.facebook-long-dark {
        @include iconBack('facebookLongDark');
      }

      .twitter a.twitter-long-dark {
        @include iconBack('share-twitter-long');
      }
    }
  }
}

.isHidden #mobile-content header.mobile-sharing-activated {
  transform: translate3d(0, -$headerPlusSharingToolsHeight, 0) !important;
}
