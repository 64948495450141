.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10001;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--mol-default-font-family);
  z-index: 10002;
}

:global(body.mol-fe-ab-dialog) {
  overflow-y: hidden;
  max-height: 100vh;
}
