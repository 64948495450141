.container {
  display: flex;
  flex-direction: column;
  background: var(--background-color);
}

.textArea {
  position: relative;
  display: flex;
  padding: 0;
  flex-grow: 1;

  .textBox {
    display: flex;
    gap: 0.5rem;
    flex-grow: 1;
  }

  .userInput {
    background: var(--background-color);
    color: var(--color-black);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 0.938rem;
    line-height: 20px;
    padding: 8px;
    flex-grow: 1;
    min-height: 54px;
    max-height: 108px;
    resize: vertical;
  }

  .userInput::placeholder {
    opacity: 0.4;
  }
}

.submitArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  p {
    color: var(--color-gray);
    font-size: 0.813rem;
    line-height: 18px;

    a {
      color: var(--channel-color-home);
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .end {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    .charCount {
      color: var(--color-gray);
      font-size: 0.813rem;
    }
  }
}

.replyBox {
  &.container {
    margin-top: 8px;
  }

  .submitArea {
    justify-content: flex-end;
  }
}
