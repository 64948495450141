.bottomLinks {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.notSure {
  flex-grow: 1;
}

.notSureButton {
  display: inline;
  border: none;
  appearance: none;
  background: none;
  color: #194fad;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.browserHelp {
  color: #194fad;
  text-decoration: none;
}
