:global {
  .bing {
    font-size: 0.83em;
    background: #003a87 url("../../assets/sprite.png") 0 -100px repeat-x;
    padding: 2px;
  }

  .bing.money {
    font-size: 0.83em;
    background: #003a87 none 0 -100px repeat-x;
    padding: 2px;
  }

  .bing.home {
    font-size: 0.83em;
    background: #003a87 url("../../assets/sprite.png") 0 -100px repeat-x;
    padding: 2px;
  }

  .bing .bing-logo {
    display: block;
    float: right;
    margin-right: 4px;
    width: 56px;
    height: 31px;
    background: url("../../assets/sprite.png") 0 -1px no-repeat;
    cursor: pointer;
  }

  .search-button.home {
    display: block;
    float: right;
    margin-right: 4px;
    width: 56px;
    height: 31px;
    background: url("../../assets/sprite.png") 0 -1px no-repeat;
    cursor: pointer;
  }

  .search-button.money {
    display: block;
    float: right;
    margin-right: 4px;
    width: 56px;
    height: 31px;
    background: none 0 -1px no-repeat;
    cursor: pointer;
  }

  .bing div.bow {
    float: left;
    width: 236px;
    border: 1px solid #004db3;
  }

  .bing .ctnr-l,
  .bing .ctnr-r {
    display: block;
    padding: 4px 4px 4px 0;
    min-height: 21px;
  }

  .bing .ctnr-l {
    width: 80px; /* MOL-4137: even width, odd pixels break browsers on zoom out */
    float: left;
    font-size: 1.1em;
    border-right: 1px solid #004db3;
    padding-right: 0;
    line-height: 21px;
  }

  .bing .ctnr-l input {
    margin: -3px 1px 0 2px;
    vertical-align: middle;
    width: 13px; /* MOL-4137: fixed width and height to prevent iPad rendering big radio buttons */
    height: 13px;
  }

  .bing .ctnr-r {
    width: 150px;
    float: right;
  }

  .bing .text-input,
  .bing button {
    vertical-align: top;
    border: 0;
  }

  .bing .text-input {
    padding: 0 4px;
    min-height: 21px;
    font-size: 1.4em;
    width: 120px;
    outline: none; /* border around the field in Chrome (orange) and Safari (blue) */
    -webkit-appearance: caret; /* MOL-4137: disable inner-shadow on textfield on the iPad */
  }

  .bing button {
    background: #d6dbd8 url("../../assets/sprite.png") 0 -50px no-repeat;
    width: 21px;
    height: 21px;
    margin-left: 1px;
    cursor: pointer;
  }

  /* MOL-4085: improve firefox dotted border around the button */
  .bing button::-moz-focus-inner {
    border: 0;
  }

  @document url-prefix() {
    .bing button:focus {
      outline: 1px black dotted;
    }
  }
}
