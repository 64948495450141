:global {
  .mol-fe-latest-headlines {
    position: relative;
    border: solid 1px #004db3;
    border-radius: 5px;
    display: none;

    ul.nav-primary {
      font-size: 1.5em;
      font-weight: bold;
      border-bottom: none;
      padding: 3px 0 0;
      background: url("https://i.dailymail.co.uk/i/furniture/structure/navigation_bottom.gif") repeat-x 0 100%;
      position: relative;

      > li > span.link-wocc.linkro-wocc > a {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }

      a {
        color: #333;
        display: block;
        padding: 4px 7px 7px;
        text-decoration: none;
        _float: left;
      }

      .link-gr6ox a,
      .link-ccox a,
      .linkro-ccox a {
        margin: 4px 0 7px;
        padding: 0 7px 0 6px;
        border-left: 1px solid #000;
      }

      li.first {
        border-left: none;
      }
    }

    .mol-fe-latest-headlines--nav {
      background-color: #e3e3e3;
      border-radius: 5px 5px 0 0;
      margin: 0 12px;
      position: relative;

      > ul {
        display: flex;

        > li {
          position: relative;

          &:not(:first-of-type)::before {
            content: " ";
            display: inline-block;
            background: linear-gradient(to bottom, #eee, #ccc);
            min-height: 14px;
            min-width: 1px;
            width: 2px;
            height: 18px;
            position: absolute;
            bottom: 8px;
            left: -1px;
          }

          &.mol-fe-latest-headlines--nav-more-wrapper {
            margin-left: auto;
            width: 68px;

            &::before {
              display: none;
            }
          }

          &[data-mol-fe-latest-headlines--active] {
            &::before,
            & + li::before {
              display: none !important;
            }
          }

          span a {
            font-size: 15px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border: none !important;
            padding-right: 6px !important;
            margin: 0;
          }
        }
      }
    }

    .mol-fe-latest-headlines--nav-more {
      position: relative;
      top: 4px;
      font-size: 18px;
      font-weight: normal;

      > ul {
        position: absolute;
        display: none;
        right: 0;
        height: 124px;
        width: 120px;
        border: 1px black solid;
        z-index: 1000;
        background-color: white;

        li a {
          display: block;
          font-size: 18px !important;
          padding: 5px 10px !important;
          margin: 0 !important;
          text-align: left;
        }
      }

      > div {
        height: 24px;
        padding: 2px 3px 0 5px;

        a {
          display: flex;
          padding: 0;

          span {
            margin: 8px 2px 0 3px;
          }
        }
      }
    }

    .mol-fe-latest-headlines--content {
      padding-top: 15px;
      position: relative;
      min-height: 150px;

      ul {
        li.sponsored {
          border: 0;
          padding: 0;
          margin-bottom: 0;
          background: none;

          .sponsoredText {
            text-transform: uppercase;
            font-weight: bold;
            color: #0cf;
          }
        }
      }
    }

    .mol-fe-latest-headlines--article {
      position: relative;
      height: 115px;
      padding-bottom: 10px;
      margin: 0 10px !important;

      img {
        position: absolute;
      }

      .comments {
        position: absolute;
        bottom: 15px;
        left: 160px;
      }
    }

    .mol-fe-latest-headlines--article-content {
      position: absolute;
      left: 154px;
      width: 455px;
      padding-left: 5px;

      h3 {
        font-size: 14px;
        padding-bottom: 3px;
      }
    }

    .mol-fe-latest-headlines--banner {
      display: none;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: whiteSmoke;
      margin: 0 10px 10px;
      font-size: 1.2em;
      font-weight: bold;

      &:not(:first-of-type) div {
        width: 100%;
        height: 20px;
        text-align: center;
      }

      &:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
        padding: 0;
        background-color: #eee;
        color: #666;
        border-radius: 2px;
        position: relative;

        &.has-more-items {
          background-color: #0049bd;
          color: #fff;
          cursor: pointer;

          .lh-new-article-count {
            position: absolute;
            top: calc(50% - 11px);
            right: 6px;
            width: 22px;
            height: 22px;
            color: #fff;
            font-size: 12px;
            font-weight: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e01c44;
            border-radius: 50%;
          }
        }
      }
    }
  }

  noscript .mol-fe-latest-headlines {
    display: block;
  }

  .fancy_headlines_wrapper noscript .mol-fe-latest-headlines .pagination {
    margin-right: 10px;
  }

  .mol-mobile .mol-fe-latest-headlines {
    border-radius: none;
    border: none;
    display: block;

    .mol-fe-latest-headlines--nav {
      margin: 0 10px;
      background-color: transparent;
      border-radius: none;
      position: relative;

      a {
        cursor: pointer;
      }

      .nav-primary {
        display: block;
      }

      ul {
        flex-wrap: wrap;
        background-color: #fff;
        position: relative;
        padding: 0;
        font-weight: normal;
        background-image: none;

        &:first-of-type {
          height: 40px;
          margin-top: 10px;
          border: solid 3px rgba(0, 77, 180, 0.3);
          overflow-y: hidden;

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background: rgba(0, 77, 180, 0.3) url("./assets/drop-arrow.svg") no-repeat center;
            width: 37px;
            height: 34px;
            visibility: visible;
          }

          &[data-expanded]::after {
            transform: rotate(180deg);
          }

          &[data-expanded] + [data-extended] {
            display: flex;
          }
        }

        li {
          width: 100%;
          height: 100%;
          font-size: 14px;

          > span {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;

            &.link-wocc.linkro-wocc > a {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }

            a {
              display: block;
              height: 100%;
              width: 100%;
              padding-left: 15px;
              padding-top: 7px;
              color: #000;
              background: none;
              font-size: 16px;
            }
          }
        }

        &[data-extended] {
          display: none;
          position: absolute;
          bottom: 0;
          transform: translateY(100%);
          z-index: 999999;
          border: solid 1px #b2cae9;
          background-color: #fffffe;
          height: 209px;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;

          li {
            font-size: 14px;
            height: 40px;

            &::before {
              display: none;
            }

            &[data-mol-fe-latest-headlines--active] {
              font-weight: bold;
              background-color: rgba(0, 77, 180, 0.05);
            }

            &.mol-fe-latest-headlines--nav-more-wrapper {
              display: none;
            }
          }
        }
      }
    }

    .mol-fe-latest-headlines--banner {
      div {
        height: auto;
      }

      &:first-of-type {
        min-height: 40px;
        font-size: 16px;
        margin: 0 20px 10px;

        &.has-more-items {
          .lh-new-article-count {
            position: absolute;
            top: calc(50% - 14px);
            right: 10px;
            width: 28px;
            height: 28px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
