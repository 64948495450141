@use "../../styles/variables" as vars;

.container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  list-style: none;
  padding: vars.$recommendationsItemPadding;
  margin: 0;
  margin-top: - 2 * vars.$recommendationsItemPadding;
}

.playButton {
  border: 10px solid transparent;
  display: inline-block;
  font-size: 1.25 * vars.$baseLine;
  height: 0;
  line-height: 0;
  vertical-align: middle;
  width: 0;
  margin: 8px 0 8px 8px;
  text-align: left;
  border-left: 16px solid #fff;
  zoom: 1;
}

.item {
  position: relative;
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: vars.$recommendationsItemPadding;
  overflow: hidden;

  > a {
    position: relative;
    overflow: hidden;
    text-decoration: none;
    font-size: vars.$fontSizeSmall;
    line-height: 1.25;
    text-align: left;
    color: #fff;

    &.active {
      &:hover {
        cursor: default;
      }

      .nowPlayingCover {
        display: block;
      }

      .bannerText {
        right: 0;
        padding: 10px;

        .playButton,
        .watchVideo {
          display: none;
        }

        .nowPlaying {
          display: block;
        }
      }
    }

    &:hover {
      .bannerText {
        right: 0;
      }

      .watchVideo {
        color: rgba(#fff, 1);
      }
    }
  }
}

.nowPlayingCover {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none;
}

.bannerText {
  background: rgba(var(--channel-color-rgb), 0.7);
  color: var(--channel-color-light);
  position: absolute;
  top: 0;
  right: -84px;
  padding: 0 8px 0 4px;
  z-index: 1;
  font-size: 0.75 * vars.$fontSize;
  font-weight: bold;
  transition: right 0.5s;

  .playButton {
    margin-right: -4px;
  }

  .nowPlaying {
    display: none;
  }

  .watchVideo {
    transition: color 0.5s;
    color: rgba(#fff, 0);
  }
}

.thumbnail {
  width: 100%;
  height: vars.$recommendationsItemThumbHeight;
  background-position: center;
  background-size: cover;
}

.description {
  width: 100%;
  overflow: hidden;
  padding: 0.375 * vars.$baseLine;
  font-weight: 300;
  background: #000;
}

.descriptionContent {
  font-size: vars.$fontSizeSmall;
  height: vars.$baseLine * 1.125 * 3;
  line-height: vars.$baseLine * 1.125;
  width: 100%;
  overflow: hidden;
}

.videoLink {
  display: block;
  width: 100%;
  height: 100%;
}

.item > a:not(.active):hover .description {
  color: var(--channel-color-light);
  background: var(--channel-color);
}

@media screen and (min-width: vars.$screenMedium) {
  .item {
    width: 50%;
  }
}

@media screen and (min-width: vars.$screenLarge) {
  .item {
    width: 50%;
  }
}

@media screen and (max-height: vars.$screenHeightSmall) {
  .item {
    width: 50%;
  }
}
