:global {
  /* related-carousel for extraWide article (default) */
  .gamma .related-carousel h2 {
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .related-carousel {
    clear: both;
    margin: 0 0 16px;

    h2 {
      font-size: 16px !important;
      line-height: 18px !important;
    }

    div.bdrcc {
      border-width: 2px 0 !important;
      padding-bottom: 10px;
    }

    .rotator,
    .rotator .rotator-panels li {
      height: 162px;
      width: 964px;
    }

    .rotator {
      .rotator-panels {
        top: 40px;

        a {
          display: block;
          float: left;
          font-weight: bold;
          height: 60px;
          margin: 1px;
          overflow: hidden;
          position: relative;
          width: 319px;
        }

        img {
          height: 60px;
          left: 0;
          position: absolute;
          top: 0;
          width: 62px;
        }

        span {
          display: block;
          font-size: 13px;
          height: 50px;
          line-height: 17px;
          overflow: hidden;
          padding: 5px 10px 0 72px;
          width: auto;
        }

        .external {
          span {
            padding-left: 10px;
          }

          i {
            background: url("//i.dailymail.co.uk/i/furniture/articles/ic_external.png") no-repeat 0 0;
            display: block;
            float: left;
            height: 10px;
            margin: 2px 7px 0 0;
            width: 10px;
          }
        }

        [data-is-sponsored] {
          span:not(.sponsored) {
            line-height: 1.1;
            font-size: 12px;
          }

          span.sponsored {
            position: absolute;
            bottom: 0;
            margin: 0;
            height: 18px;
            padding-bottom: 0;
            padding-top: 0;
            border: 0;
            background: none;
            font-size: 10px;
            color: #0cf;
            width: 100%;
            box-sizing: border-box;
            text-transform: uppercase;
          }
        }
      }

      .rotator-title {
        min-height: 40px;
        padding: 0;

        h2 {
          margin: 12px 0 0;
          text-transform: uppercase;
        }
      }

      .rotator-pages {
        li a {
          background-position: -30px -100px;
          opacity: 0.2;
        }

        .active a {
          cursor: default;
          opacity: 1;
        }

        .rotator-prev,
        .rotator-next {
          height: 20px;
          margin-top: -4px;
          width: 20px;

          a {
            background-image: url("//i.dailymail.co.uk/i/furniture/carousel_arrows_3.png");
            background-repeat: no-repeat;
            background-position: 0 -901px;
            opacity: 1;
          }
        }

        .rotator-next a {
          background-position: -21px -901px;
        }

        .disabled a {
          cursor: default;
          opacity: 0.2;
        }
      }
    }

    &.half .rotator,
    &.half .rotator .rotator-panels li {
      height: 102px;
    }
  }

  /* related-carousel for wide article */
  .wide .related-carousel {
    .rotator,
    .rotator .rotator-panels li {
      width: 636px;
    }

    .rotator .rotator-panels a {
      width: 316px;
    }
  }

  /* related-carousel for narrow article */
  .article-advert .related-carousel {
    .rotator,
    .rotator .rotator-panels li,
    .rotator .rotator-panels a {
      width: 470px;
    }
  }

  /* related-carousel with fb/tw button */
  .related-carousel.with-fb-or-tw {
    position: relative;
    margin-left: 164px;

    .rotator,
    .rotator .rotator-panels li {
      width: 800px;
    }

    .rotator .rotator-panels a {
      width: 264px;
    }
  }

  .wide .related-carousel.with-fb-or-tw {
    .rotator,
    .rotator .rotator-panels li {
      width: 472px;
    }

    .rotator .rotator-panels a {
      width: 234px;
    }
  }

  .article-advert .related-carousel.with-fb-or-tw {
    .rotator,
    .rotator .rotator-panels li,
    .rotator .rotator-panels a {
      width: 306px;
    }
  }

  /* related-carousel fb share */
  .related-carousel .fb,
  .related-carousel .tw {
    height: 172px;
    left: -164px;
    position: absolute;
    top: 0;
    width: 144px;

    h2 {
      margin: 12px 0 0;
      text-transform: uppercase;
    }

    .share {
      cursor: pointer;
      height: 40px;
      margin: 18px 0;
      width: 115px;
    }

    .big-share {
      cursor: pointer;
      height: 50px;
      margin: 27px 0 45px;
      width: 140px;

      &:focus,
      &:hover {
        background-position: 0 0;
      }
    }

    .count {
      border-top: 1px solid #c0c0c0;
      font-size: 12px;
      padding-top: 4px;

      span {
        font-size: 35px;
        font-weight: bold;
        line-height: 1em;
      }
    }
  }

  .related-carousel .fb {
    border: solid #3b579d;
    border-width: 2px 0;

    .share {
      background: url('//i.dailymail.co.uk/i/furniture/articles/spt_socialicons_40x40eachbttn9.png') no-repeat -707px -40px;

      &:focus,
      &:hover {
        background-position: -707px 0;
      }
    }

    .big-share {
      background: url('//i.dailymail.co.uk/i/furniture/articles/spt_fb_big.png') no-repeat 0 -50px;
    }
  }

  .related-carousel .tw {
    border: solid #000;
    border-width: 2px 0;

    .share {
      background: url('../assets/share-x-long.svg');
      background-repeat: no-repeat;

      &:focus,
      &:hover {
        opacity: 0.8;
      }
    }

    .big-share {
      background: url('../assets/share-x-long.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .related-carousel.half .fb,
  .related-carousel.half .tw {
    height: 112px;

    .share {
      margin: 12px 0;
    }
  }
}
