:global {
  .dm-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .dm-tabs li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    font-weight: bold;
  }

  .dm-tabs li a {
    display: block;
    float: left;
    outline: none;
  }

  .dm-tab-pane-hidden {
    display: none;
  }

  .dm-tab-on a {
    cursor: default;
  }
}
