:global {
  .newsletter-modal-shopping {
    // This value makes it sit on top of commercials
    z-index: 10001;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    padding: 0 15px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      [data-mol-fe-xpmodule-newsletter-sign-up] {
        max-width: 400px;
      }
    }

    &-bg {
      position: absolute;
      background: #0009;
      width: 100%;
      height: 100%;
    }

    &-container {
      padding: 0;
      background: #fff;
      position: relative;

      .newsletter-modal-shopping-close {
        right: 15px;
        top: 17px;
        outline: none;
        appearance: none;
        border: none;
        background-color: unset;
        background-image: url("./close.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        padding-right: 0;

        @media only screen and (max-width: 500px) {
          width: 15px;
          height: 15px;
        }
      }
    }

    &-close {
      position: absolute;
      cursor: pointer;
    }
  }
}
