.facebook-like-static {
  margin-left: 5px;
  width: 76px;
  height: 20px;
  cursor: pointer;

  img {
    height: 20px;
  }
}
