.collapse,
.viewMore {
  display: flex;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    margin: 8px 0;
    padding: 0;
    border: none;
    font-size: 0.813rem;
    font-weight: 700;
    color: var(--channel-color-home);
  }
}

.shadowComment {
  background: var(--shadow-base-color);
  display: flex;
  margin: 0 !important;
  padding: 0 1rem;
  box-shadow: inset 4px 0 var(--channel-color-home);
}

.shadowComment::before,
.collapse::before,
.viewMore::before {
  display: block;
  content: '';
  flex-shrink: 0;
}

.collapse::before {
  width: 18px;
  border-right: 1px solid var(--path-color);
  margin-right: 27px;
}

.viewMore::before {
  margin-left: 18px;
  width: 27px;
  height: 18px;
  border-bottom-left-radius: 17px;
  border-left: 1px solid var(--path-color);
  border-bottom: 1px solid var(--path-color);
}

.shadowComment::before {
  width: 18px;
  border-right: 1px solid var(--path-color);
}

.shadowComment.last::before {
  border-right: 1px solid var(--shadow-base-color);
}

.chevronIcon {
  display: inline-block;
  background: url('../assets/chevron.svg') no-repeat center;
  width: 14px;
  height: 14px;
}

.collapse .chevronIcon {
  transform: rotate(180deg);
}

.loader {
  padding-bottom: 8px;
}
