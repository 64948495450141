.thanks {
  width: 618px;
  height: 58px;
  background-color: #119511;
  position: fixed;
  top: 0;
  left: calc(50% - 618px / 2);
  transition: 500ms opacity linear;
  z-index: 10001;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--mol-default-font-family);
}

.closing {
  opacity: 0;
}

.closeButton {
  width: 58px;
  height: 58px;
  position: absolute;
  right: 0;
  top: 0;
  appearance: none;
  border: none;
  background-color: transparent;
  background-image: url("./assets/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  cursor: pointer;
  outline: none;
}
