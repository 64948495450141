.container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.dot {
  background: #c3c3c3;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
}

.dot.active {
  background: var(--color-black);
}
