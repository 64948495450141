.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.userline {
  color: var(--color-gray);
  font-size: 13px;
  line-height: 18px;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 24px;

  a {
    color: var(--color-black);
    font-weight: 700;

    &:hover {
      opacity: 0.8;
    }
  }
}

/* Safari 15/16 line clamp */
.userline > * {
  display: inline;
}
