.container {
  color: #000;
}

.heading {
  font-size: 24px;
  font-weight: 600;
}

.columns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.column {
  width: calc(50% - 1px);
  box-sizing: border-box;

  &:first-of-type {
    border-right: 1px solid #e3e3e3;
    padding-right: 40px;
  }

  &:last-of-type {
    border-left: 1px solid #e3e3e3;
    padding-left: 40px;
  }
}

.singleColumn {
  width: 100%;
}

.columnTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.columnContent {
  margin-top: 20px;
}

.step {
  font-size: 14px;

  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

.stepLabel {
  font-weight: 600;
  margin-bottom: 2px;
}

.stepText {
  line-height: 1.3;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.stepImage {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
