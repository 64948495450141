.container {
  width: 680px;
  height: 320px;
  box-sizing: border-box;
  background-color: white;
  background-image: url("./assets/tim.svg");
  background-size: 75px 75px;
  background-position: 53px 55px;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-left: 154px;
  padding-right: 74px;
  border: solid 10px #a1a1a1;
  flex-shrink: 0;
}

.heading {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 10px;
}

.text {
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 20px;
}

.viewGuide {
  display: inline;
  border: none;
  appearance: none;
  background: none;
  font-size: 16px;
  color: #194fad;
  cursor: pointer;
  padding: 0;
  outline: none;
}
