.contentLogo {
  width: auto;
  height: 40px;
}

.contentPrimaryTitle {
  font-size: 26px;
  font-weight: 600;
}

.contentSecondaryTitle {
  font-size: 20px;
  font-weight: 600;
}

.secondaryTitleMailPlus {
  font-size: 1.5rem;
  font-weight: 600;
}

.contentSecondaryTitleEssential {
  font-size: 1rem;
  font-weight: 400;
  padding: 0 15px;

  p {
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }

  a {
    font-weight: 600;
    color: var(--mol-fe-paywall-modal-bg-primary);
  }
}

.contentTertiaryTitle {
  font-size: 18px;
  font-weight: 600;
}

.contentText {
  font-size: 16px;
  font-weight: 600;
}

.contentTextRegular {
  font-size: 16px;
  font-weight: 400;
}

.contentSmallText {
  font-size: 14px;
  font-weight: 400;
}

.smallTextMailPlus {
  font-size: 1rem;
  font-weight: 400;
}

.contentSmallTextBold {
  font-size: 0.875rem;
  font-weight: 600;
}

.contentTextItalic {
  font-style: italic;
}

.headerLogo {
  width: auto;
  height: 40px;
}

.headerTitle {
  font-size: 30px;
  font-weight: 500;
  white-space: pre-wrap;
}

.headerTitleMailPlus {
  font-size: 2rem;
  font-weight: 600;
  white-space: pre-wrap;
}

.textBold {
  font-weight: 700;
  font-size: 2rem;
}

.marginBottomZero {
  margin-bottom: 0 !important;
}

.headerSecondaryTitle {
  font-size: 26px;
  font-weight: 600;
}

.headerText {
  font-size: 16px;
  font-weight: 400;
  white-space: pre-wrap;

  &Essential {
    font-size: 1.125rem;
  }
}

.activeEmail,
.inactiveEmail {
  font-size: 16px;
  font-style: italic;
  max-width: 100%;
  overflow: auto;
  padding: 0 10px;
}

.inactiveEmail {
  color: #8d8d8d;
}
