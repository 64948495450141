.container {
  width: 888px;
  box-sizing: border-box;
  background-color: white;
  padding: 32px 32px 8px;
  border: solid 10px #a1a1a1;
  flex-shrink: 0;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  width: 488px;
  text-align: center;
  margin: auto auto 32px;
}
