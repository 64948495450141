.video-lead-info {
  width: 100%;
  background: #333;
  padding: 20px;
  color: #fff;
  font-family: var(--mol-default-font-family);
  box-sizing: border-box;

  .video-headline {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;

    a {
      color: inherit;
    }
  }

  .video-description {
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
  }

  .video-title {
    margin-top: 10px;

    h1,
    h3,
    p {
      display: inline;
      font-size: 14px;
    }

    h1 {
      font-weight: normal;
    }
  }

  .video-footer {
    margin-top: 22px;
    position: relative;
    color: #999;
    height: 30px;

    .read-article {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      padding-right: 12px;
      background-image: url("./assets/chevron.svg");
      background-size: 8px 13px;
      background-position: right 1px;
      background-repeat: no-repeat;
    }
  }
}

.mol-desktop {
  .video-lead-info {
    .video-headline {
      a {
        &:hover {
          color: #999;
        }
      }
    }

    .video-footer {
      .video-lead-social {
        display: flex;
        flex-flow: row nowrap;

        ul {
          display: flex;
          flex-flow: row nowrap;

          li {
            height: 30px;
            width: 30px;
            background-size: 30px 30px;
            background-repeat: no-repeat;
            cursor: pointer;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }

            a {
              display: block;
              width: 100%;
              height: 100%;
            }

            &:not(:first-of-type) {
              margin-left: 10px;
            }

            &.share-facebook {
              background-image: url('./assets/facebook-outline.svg');
              width: 86px;
              background-size: 86px 30px;
            }

            &.share-twitter {
              background-image: url('./assets/twitter-outline.svg');
            }

            &.share-email {
              background-image: url('./assets/email-outline.svg');
            }

            &.share-pinterest {
              background-image: url('./assets/pinterest-outline.svg');
            }

            &.share-icons {
              background-image: url('./assets/share-outline.svg');
            }
          }
        }

        .video-share-count {
          margin-top: -3px;
          margin-left: 10px;

          .count {
            height: 20px;
            font-size: 20px;
            font-weight: 500;
          }

          .label {
            height: 13px;
            font-size: 13px;
          }
        }
      }

      .read-article {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.mol-mobile {
  .video-lead-info {
    padding: 10px;
    margin-bottom: 10px;

    .video-headline {
      font-size: 16px;
      line-height: 1.25;
    }

    .video-description {
      font-size: 14px;
      line-height: 1.3;
    }

    .video-footer {
      height: auto;

      .read-article {
        @media screen and (max-width: 409px) {
          position: static;
          display: inline-block;
          margin-top: 12px;
        }
      }
    }

    .social {
      margin: 0;

      ul {
        padding: 0;
        border: 0;
        background: none;
        height: 30px;

        li {
          margin: 0 4px 0 0;
          width: auto;
        }
      }

      .share-count {
        float: left;

        b {
          font-size: 24px;
          line-height: 18px;
          padding-left: 7px;
          display: block;
          text-align: right;
          color: #fefefe;
        }

        span {
          height: 14px;
          font-size: 13px;
          font-weight: normal;
          color: #fff;
          float: left;
          padding: 0 0 0 6px;
        }
      }
    }
  }
}
