.container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background: var(--color-darkgray);
  flex-shrink: 0;

  &.main {
    width: 2.75rem;
    height: 2.75rem;

    span {
      font-size: 1rem;
    }
  }

  span {
    color: var(--color-white);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    flex-shrink: 0;
    line-height: 12px;
  }

  .avatarIcon {
    display: inline-block;
    background: url('../assets/profile.svg') no-repeat center;
    width: 16px;
    height: 18px;
  }
}
