.arrowFactor {
  width: 200px;
  margin-left: auto;

  .header {
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: #e3e3e3;

    h2 {
      padding: 5px;
      font-size: 16px;
    }

    .tooltip {
      margin: 3px 5px 0;
      width: 20px;
      height: 20px;
      background: url("https://i.dailymail.co.uk/i/furniture/reg-new/spt_edit_profile.png") no-repeat 0;
      position: relative;
      cursor: pointer;

      .arrow {
        width: 10px;
        height: 27px;
        background-image: url("https://i.dailymail.co.uk/i/furniture/reg-new/spt_popboxes.png");
        background-position: -451px 0;
        position: absolute;
        left: 21px;
        top: -4px;
        z-index: 2;
      }

      .content {
        position: absolute;
        display: block;
        padding: 10px;
        width: 176px;
        background: #004ab7;
        color: #fff;
        left: 30px;
        top: -30px;
        z-index: 1;
        font-size: 11px;
        box-shadow: 1px 1px 5px #000;
        border-radius: 8px;
      }
    }
  }

  ul {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    margin-top: 1px;
    padding-top: 4px;
    background: #e3e3e3;

    li {
      padding: 5px 5px 4px 6px;
      margin-right: 1px;
      max-height: 25px;
      color: #004db3;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
      border-radius: 5px 5px 0 0;
      background: #c2c2c2;
      text-align: center;

      &.tabItemSelected {
        background: #f5f5f5;
      }
    }
  }
}

.factsContainer {
  background: #e3e3e3;

  .topRow {
    background: #f5f5f5;
    height: 36px;
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;

    div {
      width: 50%;
      font-size: 15px;
      font-weight: 700;
      color: #0cac0c;
      display: flex;
      align-items: center;

      span {
        display: block;
        height: 30px;
        width: 40px;
        background: url("https://i.dailymail.co.uk/i/furniture/comments/arrow_factor/spt_arrows_commentrating_3.png") no-repeat scroll 10px -94px transparent;
        margin-bottom: 5px;
      }
    }

    .right {
      color: red;

      span {
        background-position: -40px -94px;
      }
    }
  }

  .midRow {
    background: rgb(0, 0, 0);
    background: url('https://i.dailymail.co.uk/i/furniture/comments/arrow_factor/spt_gradients.png') repeat scroll 0 0 transparent;
    box-sizing: border-box;
    height: 60px;
    font-weight: 700;
    padding: 10px 5px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    color: #0cac0c;
    font-size: 20px;

    span {
      height: 50px;
      width: 50px;
      display: block;
      margin-left: 5px;
      margin-top: 5px;
      background: url("https://i.dailymail.co.uk/i/furniture/comments/arrow_factor/spt_arrows_commentrating_3.png") no-repeat scroll 0 -150px transparent;
    }
  }

  .midRowDown {
    color: red;

    span {
      background-position: -100px -150px;
    }
  }

  .bottomRow {
    background: #f5f5f5;
    padding: 5px 10px;
    box-sizing: border-box;
    height: 45px;
    display: flex;
    flex-flow: column;

    p {
      font-size: 12px;
      text-align: center;
    }

    span {
      color: black;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
  }
}
