@use "_settings/largeIcons";

#dms-drawer {
  width: 0;
  height: 0;
  display: none;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
  transition-property: width, height;
  transition-duration: 0.5s;

  .social-links {
    background-color: white;
    border: 1px solid #ddd;
    width: 400px;
    height: 80px;
    padding: 15px 13px;
    visibility: hidden;
  }

  li {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    float: left;
    background: #eee largeIcons.$lgSpriteUrl;
    margin-bottom: 30px;
  }

  &.dms-drawer--channel li {
    margin-right: 20px;
  }

  .wrapper {
    display: none;
    float: left;
    transition: opacity 0.5s linear;
    opacity: 0;

    a {
      display: block;
      padding-left: 10px;
      padding-top: 12px;
    }

    span {
      display: block;
      margin-left: -20px;
      padding-top: 33px;
      width: 60px;
      text-align: center;
      font-size: 1.1em;
    }
  }

  .close {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 8px;
    width: 20px;
    height: 40px;
    background: largeIcons.$lgSpriteUrl -630px 0;
  }

  .facebook {
    background-position: 0 40px;

    &:hover {
      background-position: 0 0;
    }
  }

  .twitter {
    @include largeIcons.icon("share-twitter");

    background-size: contain;

    &:hover {
      opacity: 0.8;
    }
  }

  .pinterest {
    background-position: -657px 40px;

    &:hover {
      background-position: -657px 0;
    }
  }

  .reddit {
    background-position: -300px 40px;

    &:hover {
      background-position: -300px 0;
    }
  }

  .linkedin {
    background-position: -500px 40px;

    &:hover {
      background-position: -500px 0;
    }
  }

  .email {
    background-position: -150px 40px;

    &:hover {
      background-position: -150px 0;
    }
  }

  .flipboard {
    @include largeIcons.icon("flipboard");
  }

  .fbmessenger {
    @include largeIcons.icon("facebook-messenger");
  }
}

#dms-drawer-arrow {
  position: absolute;
  z-index: 200000;
  width: 30px;
  left: 20px;
  height: 20px;
  top: -7px;
  background: largeIcons.$lgSpriteUrl -600px 0;
}
