.container {
  display: inline-flex;
  gap: 0.25rem;

  button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: none;
    border: 1px solid var(--color-darkgray);

    span {
      display: block;
      width: 12px;
      height: 12px;
    }

    .meta {
      background: url("../assets/meta.svg");
    }

    .xTwitter {
      background: url("../assets/xTwitter.svg");
    }

    .whatsapp {
      background: url("../assets/whatsapp.svg");
    }

    .copy {
      background: url("../assets/link.svg");
    }
  }

  button:hover {
    opacity: 0.8;
  }
}
