:global {
  .lightbox-gallery {
    padding: 5px;

    h3 {
      background: transparent;
      font-size: 2.92em;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }

    .gallery-frame {
      position: relative;
      float: left;
      margin-right: 10px;

      img {
        display: block;
      }
    }

    .caption {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 5px;
      width: 294px;
      font-weight: bold;
      opacity: 0.9;
      filter: alpha(opacity = 90);
    }

    .hidden {
      display: none !important;
    }

    .carousel {
      .scroller {
        clear: none !important;
        margin: 0;
        width: 308px;
        height: 338px;
      }

      .itemlist {
        margin-left: -104px !important;

        li {
          width: 104px;

          span {
            bottom: 6px;
            left: 1px;
            font-weight: bold;
            font-size: 1.2em;
            opacity: 1;
            filter: none;
          }
        }

        a {
          display: block;
          margin: 0 4px 4px 0 !important;
          border-width: 3px !important;
          width: 94px;
          height: 104px;
        }
      }

      .paging-controls {
        margin-top: 10px;
        padding: 3px;
        line-height: 1.42em;

        a {
          position: static;
          font-size: 0.92em;
          font-weight: bold;
          background-image: none;
        }

        img {
          vertical-align: middle;
        }

        a.next {
          float: right;
        }

        .instruction {
          float: left;
          margin-right: 10px;
          width: 301px;
          font-size: 0.83em;
        }
      }
    }

    .x-closebutton {
      top: 5px;
      right: 5px;
      width: 14px;
      height: 14px;
      background: url("//i.dailymail.co.uk/i/furniture/misc/close.gif") no-repeat 0 0;
    }
  }

  /* carousel */

  .lightbox-gallery.hidden {
    border: none;
    background: none;
    position: absolute;
    left: 0;
    z-index: 100001 !important;
  }

  .lightbox-overlay {
    display: none;
    z-index: 2147483647 !important;
    position: absolute;
    width: 1500px;
    text-align: left;
    right: 0;
    font-size: 1.2em;

    .gallery-overlaytable {
      .corner {
        position: static;
      }

      float: right;
      margin-left: 15px;
    }

    .overlay-imageitems {
      float: right;
      margin-left: 15px;
      position: relative;
      padding: 5px;
      width: 308px;
    }

    .messages {
      position: relative;
      background: #fff;

      .buy-link {
        position: absolute;
        top: -24px;
        font-size: 1.17em;
      }
    }

    .more-link {
      padding: 4px 0 0;
      width: 5.8em;

      &:hover {
        text-decoration: underline;
      }

      .arrow-small-r {
        float: right;
        margin: 3px 0 0 3px;
      }
    }

    p.mainText {
      margin: 0;
      padding: 3px 4px;
    }

    .main-overlayimage {
      display: block;
    }
  }
}
