.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

.container::after {
  display: block;
  content: '';
  width: 64px;
  height: 64px;
  background: url('../assets/spinner2.svg') no-repeat center;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
