.container {
  position: relative;
  display: flex;
  flex-direction: column;

  --color-black: #000;
  --color-white: #fff;
  --color-darkgray: #ccc;
  --color-gray: #666;
  --color-lightgray: #e3e3e3;
  --border-color: #e3e3e3;
  --voteup-color: #0cac0c;
  --votedown-color: #e01c44;
  --votetext-color: #fff;
  --msg-success-color: #eaf7e8;
}

.heading {
  background-color: var(--color-lightgray);
  padding: 5px 7px;
  border-radius: 8px 8px 0 0;
  font-size: 1.063rem;
  color: var(--color-black);
}

.tab {
  display: inline-flex;
  flex-direction: column;
}

.tabButtons {
  cursor: pointer;
  border: none;
  border-top: 1px solid var(--color-white);
  background-color: var(--color-lightgray);
  padding: 7px;
  font-size: 0.875rem;
  color: var(--channel-color-home);
  font-weight: 700;
  text-align: start;
}

.noComments {
  font-size: 0.75rem;
  padding: 10px 10px 10px 0;
}

.views {
  margin-top: 5px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: normal;
  color: var(--color-black);
  border: 1px solid #ececec;
  background: #f9f9f9;
  padding: 5px;
}
