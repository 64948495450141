.container {
  --comment-bg-color: var(--color-white);

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0 6px;
  border-top: 1px solid var(--border-color);
  background: var(--comment-bg-color);

  .reportFlag {
    position: absolute;
    right: 0;
    top: 15px;
    background: url('../assets/reportFlag.svg') no-repeat center;
    width: 24px;
    height: 24px;
    border: none;
    flex-shrink: 0;
    visibility: hidden;

    &:hover {
      opacity: 0.8;
    }
  }

  &:hover .reportFlag {
    visibility: visible;
  }

  &.parent {
    gap: 0;
    padding-bottom: 0;

    &.highlightComment {
      padding-bottom: 0;
    }
  }

  &.reply {
    flex-direction: row;
    gap: 0;
    padding: 0;
    border-top: unset;

    &::before {
      display: block;
      content: '';
      flex-shrink: 0;
      width: 18px;
      border-right: 1px solid var(--path-color);
    }

    &.highlightComment {
      padding: 0 1rem;
    }
  }

  &.noPath {
    &::before {
      border-right: 1px solid var(--comment-bg-color);
    }
  }

  &.highlightComment {
    --comment-bg-color: var(--highlight-base-color);

    box-shadow: inset 4px 0 var(--channel-color-home);
    margin: 0;
    padding: 8px 1rem 6px;
  }
}

.commentFooter {
  display: flex;
  justify-content: space-between;
}

.commentAction {
  display: inline-flex;
  gap: 3rem;
}

.buttonText {
  cursor: pointer;
  color: var(--channel-color-home);
  font-size: 0.813rem;
  font-weight: 700;
  line-height: 18px;
  border: none;
  background: none;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
}
