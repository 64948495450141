@use 'sass:color';

:global {
  .hp-start-news .hp-panel:last-of-type {
    display: none;
  }

  .hp-start-showbiz .hp-panel:first-of-type,
  html.tvshowbiz-hp-placeholder .hp-panel:first-of-type {
    display: none;
  }

  .hp-start-cover .hp-panel {
    display: none;
  }

  // cover
  .hp-cover {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1003; // covers everything

    &.gdpr-settings-open,
    &.ccpa-settings-open {
      z-index: 1003 !important;
    }
  }

  .mol-desktop .hp-cover {
    z-index: 10003;
  }

  // dialog
  .hp-shadow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#fff, 0.5);
  }

  .hp-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -140px;
    margin-top: -62px;
    width: 280px;
    height: 115px;

    &.tall {
      height: 160px;
    }

    &.gdpr-controls.tall {
      height: 310px;

      .hp-btn.uk {
        bottom: 94px;
      }
    }

    &.ccpa-controls.tall {
      height: 310px;

      .hp-btn.uk {
        bottom: 94px;
      }
    }

    &.desktop {
      height: 193px;
      width: 773px;
      margin-left: -386px;
      margin-top: -96px;

      .close {
        float: right;
        position: relative;
        top: -25px;
        font-size: 271%;
        color: white;
        background-color: black;
        right: -25px;
        width: 30px;
        text-align: center;
        border-radius: 30px;
        cursor: pointer;
      }

      .close::after {
        content: '×';
      }

      h3 {
        margin-top: 32px;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        color: #000;
      }
    }

    &.gdpr-controls {
      height: 382px;
      margin-top: -191px;

      .hp-btn {
        top: 227px;
        bottom: unset;

        &.home,
        &.tvshowbiz {
          top: 168px;
          bottom: auto;
        }
      }
    }

    &.ccpa-controls {
      height: 312px;
      margin-top: -156px;

      .hp-btn {
        top: 227px;
        bottom: unset;

        &.home,
        &.tvshowbiz {
          top: 168px;
          bottom: auto;
        }
      }
    }

    .hp-gdpr-explain {
      padding: 10px;
      position: relative;

      .hp-gdpr-heading {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 0;
        text-align: left;
        color: #000;
      }

      .hp-gdpr-choose-option {
        font-size: 14px;
        margin-top: 16px;
      }

      .hp-gdpr-tooltip-button {
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url("./images/tooltip.svg");
        background-size: cover;
        position: relative;
        top: 2px;

        &::after {
          content: "";
          position: absolute;
          border-width: 18px;
          border-style: solid;
          border-color: transparent transparent #f4f9ff;
          z-index: 10;
          display: none;
          bottom: -21px;
          left: -12px;
        }

        > i {
          content: "";
          position: absolute;
          border-width: 16px;
          border-style: solid;
          border-color: transparent transparent #999;
          z-index: 8;
          display: none;
          bottom: -18px;
          left: -10px;
        }
      }

      .hp-gdpr-tooltip-content {
        display: none;
        position: absolute;
        left: -6px;
        width: calc(100% + 12px);
        background: #f4f9ff;
        border: 1px solid #999;
        padding: 8px;
        box-sizing: border-box;
        font-size: 12px;

        p {
          font-weight: bold;
          padding-right: 16px;
        }

        ul {
          padding: 0;
          margin: 0;
          margin-top: 8px;

          li {
            i {
              font-size: 8px;
              border-radius: 50%;
              background-color: #1397c3;
              width: 14px;
              display: inline-block;
              height: 14px;
              text-align: center;
              color: #fff;
              font-style: normal;
              line-height: 14px;
              position: relative;
              top: -2px;
              margin-right: 2px;
            }

            &:not(:first-of-type) {
              margin-top: 4px;
            }
          }
        }

        .close {
          background-image: url("./images/close.svg");
          width: 16px;
          height: 16px;
          opacity: 0.8;
          position: absolute;
          top: 8px;
          right: 8px;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border: none;
        }
      }

      [data-showing-gdpr-tooltip="collection"] & {
        [data-hp-tooltip="collection"] {
          display: block;
          bottom: -19px;
          z-index: 1;
        }

        [data-hp-tooltip-button="collection"] {
          &::after,
          > i {
            display: block;
          }
        }
      }

      [data-showing-gdpr-tooltip="usage"] & {
        [data-hp-tooltip="usage"] {
          display: block;
          bottom: -97px;
          z-index: 1;
        }

        [data-hp-tooltip-button="usage"] {
          &::after,
          > i {
            display: block;
          }
        }
      }

      > ul {
        font-size: 14px;
        line-height: 1.3;
        margin-top: 12px;

        li {
          padding-left: 14px;
          position: relative;

          &:not(:first-of-type) {
            margin-top: 6px;
          }

          &::after {
            content: " ";
            display: block;
            position: absolute;
            left: 0;
            top: 5px;
            width: 7px;
            height: 7px;
            background: #000;
            border-radius: 50%;
          }
        }
      }
    }

    .hp-gdpr-settings {
      letter-spacing: 0;
      color: #003580;
      position: absolute;
      top: 273px;
      text-decoration: none;
      width: 100%;

      p {
        font-size: 11px;
        line-height: 16px;
        color: #333;
        text-align: left;
        margin: 0 10px;
      }

      a {
        font-size: 12px;
        display: block;
        margin: 10px;
        background-color: #eee;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #333;
        font-weight: bold;
      }
    }

    .hp-ccpa-settings {
      letter-spacing: 0;
      color: #003580;
      position: absolute;
      top: 273px;
      text-decoration: none;
      width: 100%;

      a {
        font-size: 13px;
        margin: 4px 10px;
        color: #004db4;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    background: #fff;
    box-shadow: 0 0 0 10px rgba(#000, 0.15);
  }

  .hp-btn {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 260px;
    padding: 8px 4px;
    line-height: 17px;
    display: block;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    border: 1px solid var(--grey-color-gr2);
    border-radius: 2px;
    color: var(--channel-color-home);
    box-sizing: border-box;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: auto 60%;
    background-position-y: center;
    background-position-x: 10px;

    &.uk {
      bottom: 55px;
      background-image: url("./images/GBR.svg");
    }

    &.us {
      background-image: url("./images/USA.svg");
    }

    &.home.cc {
      background-color: #004db3;
    }

    &.tvshowbiz.cc {
      background-color: #e01c44;
    }
  }

  .hp-btn-desktop {
    position: absolute;
    bottom: 55px;
    width: 300px;
    height: 50px;
    padding: 8px 4px;
    line-height: 33px;
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: 1px solid var(--grey-color-gr2);
    border-radius: 2px;
    color: var(--channel-color-home);
    box-sizing: border-box;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position-y: center;
    padding-left: 30px;
    background-position-x: 15px;

    &.us {
      background-image: url("./images/USA.svg");
      left: 77px;
      background-color: var(--channel-color-home);
      color: white;
    }

    &.uk {
      background-image: url("./images/GBR.svg");
      left: 397px;
    }
  }

  .hp-btn.home,
  .hp-btn.tvshowbiz {
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: auto;
    width: 125px;
    margin-left: 0;
    color: #fff;
    border: 0;
  }

  .hp-btn.tvshowbiz {
    position: absolute;
    left: auto;
    right: 10px;
  }

  .hp-hidden {
    display: none;
  }

  // swipeIndicator
  .hp-swipe {
    z-index: 10000;
    position: fixed;
    top: 50%;
    margin-top: -30px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &[data-hp-action] {
      &::before {
        content: '\00a0';
        display: inline-block;
        height: 60px;
        width: 30px;
      }

      &::after {
        content: '\00a0';
        display: inline-block;
        height: 15px;
        width: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -8px;
        background: linear-gradient(0deg, #fff, #fff 3px, transparent 3px), linear-gradient(90deg, #fff, #fff 3px, transparent 3px);
      }
    }
  }

  .hp-swipe[data-hp-action="swipeleft"] {
    right: 0;

    &::before {
      background: color.scale(#e01c44, $alpha: -10%);
      border-radius: 30px 0 0 30px;
    }

    &::after {
      margin-left: -9px;
      transform: rotate(-135deg);
    }
  }

  .hp-swipe[data-hp-action="swiperight"] {
    left: 0;

    &::before {
      background: color.scale(#004db3, $alpha: -10%);
      border-radius: 0 30px 30px 0;
    }

    &::after {
      margin-left: -5px;
      transform: rotate(45deg);
    }
  }

  // back to whole hp button
  .hp-back {
    padding: 6px 10px;

    a {
      width: 110px;
      height: 34px;
      padding: 5px 5px 5px 10px;
      border: 1px solid rgba(#fff, 0.4);
      border-radius: 2px;
      font-size: 11px;
      line-height: 11px;
      text-align: center;
      text-transform: none;
      color: white;
      text-decoration: none;
      box-sizing: border-box;

      &::before {
        content: '\00a0';
        float: left;
        display: inline-block;
        background-image: url("./images/desktop.svg");
        vertical-align: middle;
        background-size: 24px 22px;
        background-repeat: no-repeat;
        width: 27px;
        height: 22px;
      }
    }
  }

  .hp-page header {
    li {
      border: 0;
    }

    li:last-of-type {
      display: none;
    }
  }

  #mobile-content > header {
    .home-tabs-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: auto;
      border-bottom: 5px solid #fff;
      box-sizing: border-box;
      background: #00367d;
      padding: 10px 10px 0;

      .hp-tab-button {
        width: calc(50% - 5px);
        appearance: none;
        background: none;
        color: #fff;
        cursor: pointer;
        padding: 0;
        outline: none;
        font-weight: normal;
        font-size: 16px;
        font-family: var(--mol-default-font-family);
        box-sizing: border-box;
        height: 35px;
        margin-bottom: 10px;
        border: none;
      }

      .hp-tab-button-news {
        background: #004db3;
      }

      .hp-tab-button-showbiz {
        background: #e01c44;
      }

      &[data-selected-hp-tab='news'] .hp-tab-button-news {
        background: #fff;
        color: #004db3;
        height: 45px;
        padding-bottom: 10px;
        margin-bottom: 0;
        font-weight: bold;
      }

      &[data-selected-hp-tab='showbiz'] .hp-tab-button-showbiz {
        background: #fff;
        color: #e01c44;
        height: 45px;
        padding-bottom: 10px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
  }

  html.mobile-home-tabs {
    body.homehome,
    body.ushomeushome,
    body.auhomeauhome {
      &.isHidden #mobile-content header.mobile-sharing-activated {
        transform: translateY(-46px) !important;
      }
    }
  }
}
