:global {
  .bn-bnnr-trpl,
  .bn-bnnr {
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    user-select: none;
    padding: 3px 2px;
  }

  .bn-bnnr-trpl .b-annc,
  .bn-bnnr .b-annc {
    color: white;
    padding: 2px 10px 2px 9px;
    margin: 0 5px 0 2px;
    float: left;
    width: 115px;
    height: 14px;
    overflow: hidden;
    text-align: center;
  }

  .bn-bnnr-trpl .itms {
    float: left;
    width: 84%;
    padding-top: 2px;
  }

  .bn-bnnr .itms {
    float: left;
    width: 76%;
    padding-top: 2px;
  }

  .bn-bnnr-trpl .b-cont,
  .bn-bnnr .b-cont {
    overflow: hidden;
    position: relative;
  }

  .bn-bnnr-trpl .b-item,
  .bn-bnnr .b-item {
    display: none;
    padding: 1px 0;
  }

  .bn-bnnr-trpl .b-item div,
  .bn-bnnr .b-item div {
    position: absolute;
  }

  .bn-bnnr-trpl .first,
  .bn-bnnr .first {
    color: black;

    a {
      color: black;
    }
  }

  .bn-bnnr-trpl div.first,
  .bn-bnnr div.first {
    display: inline-block;
    width: 100%;
    height: 13px;
    overflow-x: hidden;
  }

  .bn-bnnr-trpl div.first a,
  .bn-bnr div.first a {
    color: black;
  }

  .sport-bn-bnnr-trpl,
  .sport-bn-bnnr-trpl-betway,
  .sport-bn-bnnr-trpl-sky,
  .sport-bn-bnnr,
  .sport-bn-bnnr-betway,
  .sport-bn-bnnr-sky {
    font-size: 14px;
    font-weight: bold;
    user-select: none;
    padding: 0;
    background: #ff0 url("https://i.dailymail.co.uk/i/pix/2013/07/04/ticker_bg_yellow.png") repeat-x;
    color: #000;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .sport-bn-bnnr-trpl .b-annc,
  .sport-bn-bnnr-trpl-betway .b-annc,
  .sport-bn-bnnr-trpl-sky .b-annc,
  .sport-bn-bnnr .b-annc,
  .sport-bn-bnnr-betway .b-annc,
  .sport-bn-bnnr-sky .b-annc {
    color: #ffea00;
    background: url("https://i.dailymail.co.uk/i/pix/2013/07/04/ticker_bg_black.png") repeat-x;
    padding: 13px 10px 13px 9px;
    margin: 0;
    float: left;
    width: 121px;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  [data-breaking-banner] a {
    color: inherit;
  }

  .sport-bn-bnnr-trpl .itms,
  .sport-bn-bnnr-trpl-betway .itms,
  .sport-bn-bnnr-trpl-sky .itms {
    float: left;
    width: 84%;
    padding-top: 2px;
  }

  .sport-bn-bnnr .itms,
  .sport-bn-bnnr-betway .itms,
  .sport-bn-bnnr-sky .itms {
    float: left;
    width: 76%;
    padding-top: 12px;
  }

  .sport-bn-bnnr-trpl .b-cont,
  .sport-bn-bnnr-trpl-betway .b-cont,
  .sport-bn-bnnr-trpl-sky .b-cont,
  .sport-bn-bnnr .b-cont,
  .sport-bn-bnnr-betway .b-cont,
  .sport-bn-bnnr-sky .b-cont {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
  }

  .sport-bn-bnnr-trpl .b-item,
  .sport-bn-bnnr-trpl-betway .b-item,
  .sport-bn-bnnr-trpl-sky .b-item,
  .sport-bn-bnnr .b-item,
  .sport-bn-bnnr-betway .b-item,
  .sport-bn-bnnr-sky .b-item {
    display: none;
    padding: 2px 0;
  }

  .sport-bn-bnnr-trpl .b-item div,
  .sport-bn-bnnr-trpl-betway .b-item div,
  .sport-bn-bnnr-trpl-sky .b-item div,
  .sport-bn-bnnr .b-item div,
  .sport-bn-bnnr-betway .b-item div,
  .sport-bn-bnnr-sky .b-item div {
    position: absolute;
  }

  .sport-bn-bnnr-trpl .first,
  .sport-bn-bnnr .first {
    color: black;

    a {
      color: black;
    }
  }

  .sport-bn-bnnr-trpl div.first,
  .sport-bn-bnnr-trpl-betway div.first,
  .sport-bn-bnnr-trpl-sky div.first,
  .sport-bn-bnnr div.first,
  .sport-bn-bnnr-betway div.first,
  .sport-bn-bnnr-sky div.first {
    display: inline-block;
    width: 100%;
    height: 15px;
    overflow-x: hidden;
  }

  .sport-bn-bnnr-trpl div.first a,
  .sport-bn-bnnr-trpl-betway div.first a,
  .sport-bn-bnnr-trpl-sky div.first a,
  .sport-bn-bnr div.first a,
  .sport-bn-bnr-betway div.first a,
  .sport-bn-bnr-sky div.first a,
  .sport-bn-bnnr-trpl div.first .aspan,
  .sport-bn-bnnr-trpl-betway div.first .aspan,
  .sport-bn-bnnr-trpl-sky div.first .aspan,
  .sport-bn-bnr div.first .aspan,
  .sport-bn-bnr-betway div.first .aspan,
  .sport-bn-bnr-sky div.first .aspan {
    color: black;
    padding-left: 7px;
  }

  .sport-bn-bnnr-trpl-betway,
  .sport-bn-bnnr-betway {
    background: #00a826 url("https://i.dailymail.co.uk/i/pix/2013/07/04/ticker_bg_betway_green.png") repeat-x;
  }

  .sport-bn-bnnr-trpl-betway .itms {
    width: 67%;
  }

  .sport-bn-bnnr-trpl-sky .itms {
    width: 62%;
  }

  .sport-bn-bnnr-betway .itms {
    width: 67%;
    padding-top: 2px;
  }

  .sport-bn-bnnr-sky .itms {
    width: 30%;
    padding-top: 2px;
  }

  .sport-bn-bnnr-trpl-betway .first,
  .sport-bn-bnnr-betway .first {
    color: white;

    a {
      color: white;
    }
  }

  .sport-bn-bnnr-trpl-betway div.first a,
  .sport-bn-bnr-betway div.first a,
  .sport-bn-bnnr-trpl-betway div.first .aspan,
  .sport-bn-bnr-betway div.first .aspan {
    color: white;
  }

  .sport-bn-bnnr-trpl-betway .betway-logo {
    width: 160px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .sport-bn-bnnr-trpl-sky .sky-branding {
    display: inline-block;
  }

  .sport-bn-bnnr-trpl-sky .sky-logo {
    height: 25px;
    padding-top: 9px;
    position: absolute;
    right: 20px;
  }

  .sport-bn-bnnr-trpl-sky .sky-undoing {
    height: 25px;
    position: absolute;
    right: 80px;
    margin-top: 16px;
  }

  [data-breaking-banner-scrollable-content] {
    white-space: nowrap;
    left: 0;
  }
}
