@use "../../styles/variables" as vars;

.container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0.625 * vars.$baseLine vars.$baseLine vars.$baseLine;
  color: #fff;
  margin: 0;
}

.headline {
  font-size: 1.375 * vars.$fontSize;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.5 * vars.$baseLine;
  cursor: pointer;

  a {
    display: block;
    color: #fff !important;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover a {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.previewText {
  font-size: vars.$fontSizeSmall;
  line-height: 1.3;
  font-weight: 300;
  display: block;
}

.socialShare {
  box-sizing: border-box;
  margin-top: 1.25 * vars.$baseLine;
  font-size: 0.8125 * vars.$baseLine;

  :global(ul.article-icon-links li a) {
    color: #fff !important;

    :global(.icon) {
      background-position: 0 0 !important;
      background-repeat: no-repeat !important;
    }

    &:global(.comments-link .icon) {
      background-image: url("./assets/comments.svg") !important;
    }

    &:global(.videos-link .icon) {
      background-image: url("./assets/videos.svg") !important;
    }

    &:global(.share-link .icon) {
      background-image: url("./assets/shares.svg") !important;
    }
  }
}

@media screen and (min-width: vars.$screenMedium) {
  .headline {
    font-size: 1.4 * vars.$fontSize;
  }

  .previewText {
    font-size: vars.$fontSize;
    line-height: 1.25;
  }
}

@media screen and (min-width: vars.$screenLarge) {
  .headline {
    font-size: 2 * vars.$fontSize;
  }

  .previewText {
    font-size: vars.$fontSizeLarge;
    line-height: 1.25;
  }
}

@media screen and (max-height: vars.$screenHeightSmall) {
  .headline {
    font-size: vars.$baseLine;
  }

  .previewText {
    display: none;
  }
}
