.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 8px;
  position: absolute;
  top: 100%;
  z-index: 1;

  &.success {
    background: var(--msg-success-color);

    .icon {
      background: url('../assets/tick.svg') no-repeat center;
    }
  }

  &.error {
    background: var(--msg-error-color);

    .icon {
      background: url('../assets/error.svg') no-repeat center;
    }
  }
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.line {
  color: var(--text-color);
  font-size: 0.813rem;
  font-weight: 700;
  line-height: 20px;
}
