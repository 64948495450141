:global {
  .video_carousel_container .rotator .rotator-panels {
    transition: transform 1.2s;
  }

  .video_carousel_container .rotator .rotator-panels li a span {
    transform: translateX(0);
    transition: transform 0.45s;
  }

  .video_carousel_container .rotator .rotator-panels li a:focus span,
  .video_carousel_container .rotator .rotator-panels li a:hover span {
    transform: translateX(-90px);
  }
}
