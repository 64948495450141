.container {
  min-height: 240px;
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid #e3e3e3;
  border-top: none;
  display: flex;
  flex-direction: row;
  max-height: 70vh;
  overflow-y: auto;
}

.steps {
  flex-grow: 1;
}

.image {
  flex-shrink: 0;
  margin-left: 26px;
  min-width: 280px;
  text-align: center;
}

.step {
  font-size: 16px;

  &:not(:first-of-type) {
    margin-top: 30px;
  }
}

.stepLabel {
  font-weight: 600;
}

.stepText {
  line-height: 1.25;
  margin-top: 10px;
}
