.parentComment {
  display: flex;
  flex-direction: row;

  &::before {
    display: block;
    content: '';
    flex-shrink: 0;
    width: 18px;
    border-right: 1px solid var(--path-color);
  }

  .spacing {
    flex-shrink: 0;
    width: 27px;
  }
}

.replyPath {
  flex-shrink: 0;
  width: 27px;
  height: 26px;
  margin-left: -1px;
  border-bottom-left-radius: 17px;
  border-left: 1px solid var(--path-color);
  border-bottom: 1px solid var(--path-color);
}

.comment {
  display: flex;
  flex-flow: column;
  gap: 8px;
  padding: 8px 0 6px;
  flex-grow: 1;
}
