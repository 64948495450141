:global {
  .shallow-ac.mini {
    .rotator {
      height: 146px;
      margin-left: 46px;

      .rotator-panels {
        li {
          height: 106px;
          margin-right: 4px;
          padding-bottom: 40px;
          width: 142px;
        }

        a {
          height: auto;

          > span {
            font-size: 1.3em;
            height: auto;
            max-height: 30px;
            transition: max-height 0.5s ease;
            width: 128px;
          }
        }
      }
    }

    .rotator-panels a {
      &:focus,
      &:hover {
        > span {
          max-height: 136px;
        }
      }
    }

    .rotator .rotator-pages {
      div,
      div .bg,
      div a {
        height: 146px;
        width: 42px;
      }
    }

    .rotator-pages {
      .rotator-prev {
        left: -46px;
      }

      .ico {
        margin: 57px 11px;
      }
    }
  }
}
