:global {
  /* Triple */

  .shallow-vc {
    .video-carousel-body {
      height: 171px;
    }

    .rotator {
      left: 48px;

      .rotator-panels li {
        width: 172px;
        height: 171px;
        margin-right: 2px !important;

        img {
          height: 97px;
        }
      }
    }

    .left_scroll,
    .right_scroll {
      height: 97px;
      width: 46px;
    }

    .left_scroll span,
    .right_scroll span {
      top: 32px;
      right: 13px;
    }

    .blank-div {
      height: 74px;
      width: 46px;
    }

    .video-item-title {
      font-size: 14px;
      line-height: 16px;
      padding: 5px 7px 0;
      height: 64px;
      border-width: 0 !important;
      overflow: hidden;
      color: #fff;
    }

    .playbtn {
      margin-left: 7px;
    }

    &.double-width {
      .video-carousel-body {
        height: 174px;
      }

      .rotator {
        left: 46px;

        .rotator-panels li {
          width: 180px;
          height: 174px;

          img {
            height: 101px;
          }
        }
      }

      .left_scroll,
      .right_scroll {
        height: 101px;
        width: 44px;
      }

      .left_scroll span,
      .right_scroll span {
        top: 34px;
        right: 12px;
      }

      .blank-div {
        width: 44px;
      }
    }
  }

  /* Double */
}
