:global {
  [data-accordion] {
    [data-accordion-header] {
      [data-accordion-header-link] {
        cursor: pointer;

        &:hover {
          box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
        }
      }

      [data-accordion-content] {
        display: none;
      }

      &[data-accordion-expanded] {
        [data-accordion-content] {
          display: block;
        }
      }
    }
  }
}
