.contentSecondaryTitle {
  font-size: 20px;
  font-weight: 600;
}

.contentTextRegular {
  font-size: 16px;
  font-weight: 400;
}

.contentTextItalic {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

.footerTextItalic {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: #1a1a1a;
  text-align: center;

  a {
    color: #1a1a1a;
    text-decoration: underline;
  }
}
