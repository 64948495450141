.wrapper {
  --mol-fe-paywall-profile-button-bg: #004db3;
  --mol-fe-paywall-profile-button-text: #fff;
  --mol-fe-paywall-profile-product-active: #0cac0c;
  --mol-fe-paywall-profile-product-inactive: #5c5c5c;
  --mol-fe-paywall-profile-product-text: #fff;
  --mol-fe-paywall-profile-text: #000;
  --mol-fe-paywall-profile-link: #004db3;

  color: var(--mol-fe-paywall-profile-text);
}

.subscriptions {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  margin-top: 14px;
}

.statusWrapper {
  display: flex;
  font-size: 0.75rem;
  margin-top: 1.438rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  overflow: auto;
  font-weight: bold;
}

.subscriberEmail {
  font-weight: normal;
}

.restorePurchase {
  font-size: 12px;
  margin-top: 14px;

  a {
    color: var(--mol-fe-paywall-profile-link);
    font-weight: bold;
  }
}

.button {
  width: 100%;
  max-width: 200px;
  padding: 9px 16px;
  box-sizing: border-box;
  background: var(--mol-fe-paywall-profile-button-bg);
  color: var(--mol-fe-paywall-profile-button-text);
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
    text-decoration: none !important;
  }
}

.buttons {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

:global(.mol-mobile) {
  .statusWrapper {
    margin-top: 20px;
    font-size: 16px;
  }
}
