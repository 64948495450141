:global {
  .carousel-holder a {
    outline: none;
    text-decoration: none;

    &:hover {
      outline: none;
      text-decoration: none;
    }
  }

  .carousel {
    position: relative;

    .scroller {
      position: relative;
      margin: 0 auto;
      overflow: hidden;
    }

    .itemlist {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 0.83em !important;
      position: relative;

      li {
        position: relative;
        margin: 0 !important;
        padding: 0 !important;
        list-style: none;
        overflow: hidden;

        ul {
          margin: 0 !important;
          padding: 0 !important;
        }

        span {
          position: absolute;
          display: block;
          padding: 2px 4px;
          opacity: 0.85;
          filter: alpha(opacity = 85);
        }

        img {
          display: block;
        }
      }
    }

    .paging-controls {
      position: relative;

      a {
        position: absolute;
        background-image: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_hz_sprite_7.png");
        background-repeat: no-repeat;
        background-position: 0 0;

        &.next {
          right: 0;
        }

        &.previous {
          left: 0;
        }
      }
    }
  }

  /* carousel structure */

  /* horizontal only */

  .carousel-holder .horizontal {
    .scroller {
      clear: both;
    }

    .itemlist {
      width: 5000px;
      position: relative;
      left: 0;
      top: 0;

      li {
        float: left;
      }
    }
  }

  /* buy link */

  .buy-link-container {
    margin-bottom: 5px;
  }

  .buy-link {
    font-weight: bold;
    padding: 4px;
  }

  /* close button */

  .x-closebutton {
    position: absolute;
    top: 9px;
    right: 11px;
    z-index: 9999;
    text-indent: -1000px;
    overflow: hidden;
    width: 10px;
    height: 10px;
    background: url("//i.dailymail.co.uk/i/furniture/spt_icons_misc_2.gif") no-repeat -60px 0;
  }
}
