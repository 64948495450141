:global {
  /* tabs */
  .tabbed-headlines .dm-tabs li a {
    padding: 2px 0;
    font-size: 1.2em;
    width: 152px;
  }

  .tabbed-headlines .dm-tabs .tl {
    top: -1px;
    left: -1px;
  }

  .tabbed-headlines .dm-tabs .tr {
    top: -1px;
    right: -1px;
  }

  /* tab panes */
  .tabbed-headlines .drop-shadow {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }

  .tabbed-headlines .drop-shadow .bdrcc {
    border-top: 0;
    padding: 10px 0;
    overflow: hidden;
  }

  /* tab pane content and forms */
  .tabbed-headlines .arrow-small-r {
    position: absolute;
    float: none;
    top: 1px;
    left: 7px;
    color: #bfbfbf;
  }

  .tabbed-headlines a:hover .arrow-small-r {
    color: inherit;
  }

  .tabbed-headlines div li {
    position: relative;
    padding: 1px 0;
    list-style: none;
  }

  .tabbed-headlines div li.more {
    margin-top: 10px;
  }

  .tabbed-headlines div li a {
    display: block;
    padding: 0 11px 0 19px;
  }

  /* tabbed headlines with thumbnails */
  .tabbed-headlines .tmbs {
    margin-top: -7px;
  }

  .tabbed-headlines .tmbs li {
    padding: 0;
  }

  .tabbed-headlines .tmbs li a {
    padding: 3px 6px;
    min-height: 43px;
  }

  .tabbed-headlines .tmbs .bold {
    background: #f5f5f5;
  }

  .tabbed-headlines .tmbs li img {
    float: left;
  }

  .tabbed-headlines .tmbs li span {
    display: block;
    padding-left: 50px;
  }
}
