.container {
  display: inline-flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;

  label {
    color: var(--color-gray);
    font-size: 0.813rem;
    font-weight: 700;
  }
}

.downVote,
.upVote {
  cursor: pointer;
  display: flex;
  width: 4.5rem;
  padding: 3px 8px;
  align-items: center;
  gap: 0.25rem;
  border-radius: 24px;
  border: 1px solid var(--color-lightgray);
  background: none;
  font-size: 0.813rem;
  font-weight: 700;
  line-height: 18px;

  &:disabled {
    cursor: not-allowed;
  }

  span {
    flex-grow: 1;
  }

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.downVote {
  color: var(--votedown-color);

  svg {
    transform: rotate(180deg);
  }

  &.voted {
    background: var(--votedown-color);
    color: var(--votetext-color);
  }
}

.upVote {
  color: var(--voteup-color);

  &.voted {
    background: var(--voteup-color);
    color: var(--votetext-color);
  }
}
