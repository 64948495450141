.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--channel-color-home);
  gap: 0.25rem;
  height: 40px;
  color: var(--color-white);
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 24px;
  padding: 0 15px;
  white-space: nowrap;
  cursor: pointer;

  &::before {
    display: inline-block;
    content: '';
    background: url('../assets/send.svg') no-repeat center;
    width: 19px;
    height: 15px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.container.loader {
  min-width: 7.313rem;

  &::before {
    background: url('../assets/spinner.svg') no-repeat center;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
