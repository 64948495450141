:global {
  .article-text .timaccordion ul li {
    list-style-type: none;
  }

  .timaccordion .col,
  .timaccordion .pane,
  .timaccordion .el {
    overflow: hidden;
  }

  .timaccordion {
    padding: 8px 5px;
    line-height: 23px;

    .col.extend {
      width: 496px;
    }
  }

  .timaccordion .inner {
    position: relative;
  }

  .timaccordion .col {
    width: 152px;
    min-height: 181px;
    float: left;
    position: relative;
    transition: width 0.4s linear;
  }

  .timaccordion h4 {
    position: relative;
    width: 151px;
    min-height: 89px;
    background: #fff;
    z-index: 3;
    cursor: pointer;
    text-align: center;
    border-right: 1px solid #c0c0c0;
    color: #666;
    font-size: 1.17em;
  }

  .timaccordion .last {
    margin: 0;
  }

  .timaccordion .up h4 {
    border-bottom: 1px solid #c0c0c0;
  }

  .timaccordion h4:hover,
  .timaccordion .open h4 {
    color: #af1e1e;
  }

  .timaccordion .open h4 {
    cursor: default;
    background: #d5d5d5 url("//i.dailymail.co.uk/i/furniture/standard_modules/tim_accordion/spt_tim_accordion.png") repeat-x;
  }

  .timaccordion .down h4.open {
    background-position: 0 -92px;
  }

  .timaccordion h4 .cell {
    display: block;
    padding: 3px 0 0;
    min-height: 84px;
    border-left: 1px solid #fff;
  }

  .timaccordion .first h4 .cell {
    border-left: none;
  }

  .timaccordion .up h4 .cell {
    border-bottom: 1px solid #fff;
  }

  .timaccordion .down h4 .cell {
    border-top: 1px solid #fff;
  }

  .timaccordion .pane {
    width: 343px;
    position: absolute;
    top: 0;
    left: 152px;
    z-index: 1;
    padding-right: 1px;
    background: #c0c0c0;
  }

  .timaccordion div.open .pane {
    z-index: 2;
  }

  .timaccordion .pane div {
    min-height: 181px;
    padding: 0 5px 0 35px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    background: #d5d5d5 url("//i.dailymail.co.uk/i/furniture/standard_modules/tim_accordion/spt_tim_accordion.png") repeat-x;
  }

  .timaccordion h4.open,
  .timaccordion .last h4 {
    border-right: none;
    padding-right: 1px;
  }

  .timaccordion .last .pane {
    padding: 0 0 0 1px;
    width: 344px;
    margin-right: 0;
  }

  .timaccordion .pane .arr {
    background: url("//i.dailymail.co.uk/i/furniture/standard_modules/tim_accordion/spt_tim_accordion.png") left -200px no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 35px;
    height: 89px;
  }

  .timaccordion .down .arr {
    top: 92px;
    background: url("//i.dailymail.co.uk/i/furniture/standard_modules/tim_accordion/spt_tim_accordion.png") left -400px no-repeat;
  }

  .timaccordion .pane .ttl {
    font-size: 2em;
    display: block;
    padding: 2px 0 0;
    margin: 0 0 4px;
  }

  .timaccordion .pane .desc {
    margin: 0 0 15px;
    font-size: 1.5em;
  }

  .timaccordion .pane .fnote {
    font-size: 0.9em;
    position: absolute;
    left: 35px;
    bottom: 6px;
  }

  .timaccordion .pane .desc .tim-btn {
    vertical-align: middle;
  }

  .timaccordion .pane label.block {
    padding: 0 0 3px;
  }

  .timaccordion .radios li {
    padding: 0;
  }

  .timaccordion .radios li.float-l,
  .timaccordion .radios li.float-r {
    width: 140px;
  }

  .timaccordion .radios li.float-l {
    clear: both;
  }

  .timaccordion .desc .tim-btn button,
  .timaccordion .desc .tim-btn a {
    font-size: 0.83em;
  }

  .timaccordion .radios .tim-btn {
    margin-left: 150px;
  }

  .cmicn,
  .cmicn-a {
    display: block;
    width: 100px;
    height: 58px;
    margin: 5px auto 0;
    background-image: url("//i.dailymail.co.uk/i/furniture/standard_modules/tim_accordion/spt_cmicons.png");
    background-repeat: no-repeat;
  }

  .cmicn-0 { /* Insurance */
    background-position: 0 0;
  }

  .cmicn-1 {  /* Utilities */
    background-position: -150px 0;
  }

  .cmicn-2 { /* Mortgages */
    background-position: -300px 0;
  }

  .cmicn-3 { /* Shares */
    background-position: -450px 0;
  }

  .cmicn-4 { /* Credit cards */
    background-position: -600px 0;
  }

  .cmicn-5 { /* Loans */
    background-position: -750px 0;
  }

  .cmicn-6 { /* Savings */
    background-position: -900px 0;
  }

  .cmicn-7 { /* Current accounts */
    background-position: -1050px 0;
  }

  /* MOL-4062: new modules */
  .cmicn-a {
    background-image: url("//i.dailymail.co.uk/i/furniture/standard_modules/tim_accordion/spt_cmicons_a.png");
  }

  .cmicn-a-0 { /* Free guides */
    background-position: 0 0;
  }

  .cmicn-a-1 { /* Annuities */
    background-position: -150px 0;
  }

  .cmicn-a-2 { /* Broadband */
    background-position: -300px 0;
  }

  .cmicn-a-3 { /* Car insurance */
    background-position: -450px 0;
  }

  .cmicn-a-4 { /* House insurance */
    background-position: -600px 0;
  }

  .cmicn-a-5 { /* Credit check */
    background-position: -750px 0;
  }

  .cmicn-a-6 { /* Currency cards */
    background-position: -900px 0;
  }

  .cmicn-a-7 { /* Equity release */
    background-position: -1050px 0;
  }

  .cmicn-a-8 { /* Find and adviser */
    background-position: -1200px 0;
  }

  .cmicn-a-9 { /* Fund supermarket */
    background-position: -1350px 0;
  }

  .cmicn-a-10 { /* Inheritance planning */
    background-position: -1500px 0;
  }

  .cmicn-a-11 { /* Pension tracker */
    background-position: -1650px 0;
  }

  .cmicn-a-12 { /* Warranties */
    background-position: -1800px 0;
  }
}
