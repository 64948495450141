:global {
  /* shallow article carousel triple (default) */
  .shallow-ac {
    overflow: hidden;
    position: relative;

    h2 {
      &.bdrcc {
        border-width: 1px 0 0;
        font-size: 1.16em;
        margin-bottom: 8px;
      }

      span {
        display: inline-block;
        margin-right: 8px;
        padding: 8px;
        text-transform: uppercase;
      }
    }

    .rotator {
      height: 202px;
      margin-left: 48px;
      overflow: visible;
      width: auto;

      .rotator-panels {
        top: 0;

        li {
          height: 128px;
          margin-right: 2px;
          padding-bottom: 74px;
          position: relative;
          width: 172px;
        }

        img {
          height: auto;
          width: 100%;
        }

        a {
          height: 202px;

          > span {
            border-color: transparent;
            border-style: solid;
            border-width: 5px 7px;
            bottom: 0;
            font-size: 1.4em;
            font-weight: bold;
            height: 64px;
            left: 0;
            line-height: 1.15em;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 158px;
          }
        }
      }
    }

    .rotator .rotator-pages {
      div,
      .bg,
      div a {
        background-image: none;
        display: block;
        height: 128px;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        text-indent: -9999em;
        top: 0;
        width: 46px;
      }

      div {
        border-bottom: 74px solid #fff;
      }
    }

    .rotator-pages {
      position: static;

      ul {
        position: absolute;
        right: 0;
        top: -30px;
      }

      .rotator-prev {
        left: -48px;
        right: auto;
      }

      .bg {
        background: #fff;
        opacity: 0.8;
      }

      .disabled {
        a {
          cursor: default;
        }

        .bg {
          background: #efefef;
        }
      }

      .ico {
        display: block;
        height: 33px;
        left: 0;
        margin: 48px 13px;
        position: absolute;
        top: 0;
        width: 20px;
      }

      li a {
        background-position: -30px -100px;
      }
    }

    /* shallow article carousel double */
    &.double-width .rotator {
      height: 208px;
      margin-left: 46px;

      .rotator-panels {
        li {
          height: 134px;
          width: 180px;
        }

        a {
          height: 208px;

          > span {
            width: 164px;
          }
        }
      }

      .rotator-pages {
        div,
        .bg,
        div a {
          height: 134px;
          width: 44px;
        }

        .rotator-prev {
          left: -46px;
        }

        .ico {
          margin: 51px 11px;
        }
      }
    }
  }
}
