:global {
  .mol-desktop #reader-comments:not(#comments),
  .mol-desktop #reader-comments-in-overlay:not(#comments) {
    min-height: 370px !important;
    background-color: #f5f5f5;
    background-image: url("./assets/commentBubble.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6.25rem 6.25rem;

    .rc-title {
      color: #666;
      font-size: 0.813rem;
      line-height: 18px;
    }
  }

  .mol-desktop #reader-comments:has(#profile-comments-container) {
    all: unset;
  }

  .mol-desktop #reader-comments-in-overlay {
    #reader-comments-container {
      overflow: hidden !important;
    }
  }

  .mol-desktop #reader-comments-container {
    min-height: auto;
  }
}
