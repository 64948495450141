:global {
  #abuse-details {
    .reg-fld {
      display: flex;
      align-items: center;

      .reg-lbl {
        align-self: flex-start;
        padding-top: 5px;
      }
    }

    #recaptcha_widget .reg-fld {
      margin: 0;
    }

    .captcha-widget {
      transform: scale(1.24);
      transform-origin: 0 0;
      margin-left: 180px;
      margin-bottom: 50px;
    }

    #complaint {
      resize: vertical;
      min-height: 120px;
    }

    .actions-holder {
      margin-top: 16px;
      text-align: center;
      vertical-align: top;

      .reg-btn {
        display: inline-block;
        margin: 0 8px;

        &.reverse-wocc {
          background: white;
          color: #004db3;
          border: 1px solid #e3e3e3;

          &:hover {
            background: #efefef !important;
            color: #004db3 !important;
          }
        }
      }
    }
  }
}
