:global {
  .popup-carousel {
    position: relative;

    h3 {
      float: left;
      margin: 0;
      padding: 0;
      font-size: 1.17em;
      text-transform: uppercase;
      background: transparent;
    }

    p {
      float: right;
      padding: 2px 0;
      font-size: 1em;
    }

    .carousel-holder {
      clear: both;
      position: relative;
      padding: 8px;
      background: #f3f3f3;
    }

    .scroller {
      width: 565px;
    }

    .itemlist {
      margin-left: -145px !important;

      li {
        margin-right: 15px !important;
        width: 130px;
        height: 140px;
      }
    }

    ul.itemlist a {
      height: 140px;
    }

    .itemlist a span {
      font-weight: bold;
      width: 122px;

      &.ccox {
        bottom: 1.3em;
        font-size: 1.2em;
        filter: none;
        opacity: 1;
      }

      &.wocc {
        bottom: 0;

        span {
          position: static;
          padding: 0;
          filter: none;
          opacity: 1;
        }
      }
    }

    .paging-controls a {
      width: 18px;
      height: 140px;
      top: -140px;
    }

    .popup-window {
      position: absolute;
      left: 203px;
      top: -20px;
      visibility: hidden;
      z-index: 88888;
    }

    .opaque {
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: #fff;
      z-index: 10000;
    }

    table {
      .cnr-bl,
      .cnr-br,
      .cnr-tl,
      .cnr-tr {
        width: 16px;
        height: 16px;
      }

      .cnr-tl {
        background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_tl.png") no-repeat;
      }

      .cnr-tr {
        background-image: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_tr.png");
      }

      .cnr-br {
        background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_br.png") no-repeat;
      }

      .cnr-bl {
        background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_bl.png") no-repeat;
      }

      .topSpacer {
        height: 16px;
        background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_top.png") repeat-x;
      }

      .middleRight {
        width: 16px;
        background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_right.png") repeat-y;
      }

      .bottomSpacer {
        height: 16px;
        background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_bottom.png");
      }

      .middleLeft {
        width: 16px;
        background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_popup_left.png") repeat-y;
      }
    }

    .content {
      background: #fff;
    }

    .popup-container {
      position: relative;
      overflow: hidden;
    }

    table .content-bottom {
      padding: 5px 0 10px;
      font-size: 0.83em;
      font-weight: bold;
      width: 202px;
    }

    .hidden {
      display: none;
    }
  }

  /* carousel */

  /* popup window */

  /* round corners  */

  /* popup content  */

  /*  large gallery  */

  .popup-carousel-triple {
    .scroller {
      width: 875px;
    }

    .itemlist {
      margin-left: -149px !important;

      li {
        margin-right: 19px !important;
      }
    }

    .popup-window {
      left: 357px;
    }
  }
}
