.container {
  color: var(--text-color);
  font-size: 0.938rem;
  line-height: 20px;
  margin-bottom: 6px;
  white-space: pre-line;

  .seeMore {
    background: none;
    border: none;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
