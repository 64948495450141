.container {
  background: var(--shadow-base-color);
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid var(--border-color);
  margin: 0 !important;
  padding: 8px 1rem;
  box-shadow: inset 4px 0 var(--channel-color-home);
  flex-grow: 1;

  &.reply {
    border-top: unset;
    padding: 0;
    box-shadow: unset;
  }
}
