// @TODO USE baseline 16px
@use "sass:map";
@use "../../styles/variables" as vars;

@mixin setSizes($config) {
  $width: map.get($config, width);
  $height: map.get($config, height);
  $panelRightWidth: map.get($config, panelRightWidth);
  $panelRightHeadFont: map.get($config, panelRightHeadFont);
  $panelRightFootFont: map.get($config, panelRightFootFont);
  $panelRightHeadHeight: map.get($config, panelRightHeadHeight);
  $panelRightFootHeight: map.get($config, panelRightFootHeight);

  .container {
    width: $width;
    min-height: $height;
  }

  .panelRight {
    padding-top: $panelRightHeadHeight;
    padding-bottom: $panelRightFootHeight;
    width: $panelRightWidth;
  }

  .panelLeft {
    width: $width - $panelRightWidth;
  }

  .panelRightFoot {
    height: $panelRightFootHeight;
    line-height: $panelRightFootHeight;
    font-size: $panelRightFootFont;
  }

  .panelRightHead {
    height: $panelRightHeadHeight;
    line-height: $panelRightHeadHeight;
    font-size: $panelRightHeadFont;
    text-transform: uppercase;
  }
}

.container {
  position: relative;
  background: #000;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
  text-align: left;

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }
}

.panelLeft {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.panelRight {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.panelRightHead {
  padding-left: vars.$panelRightItemsPadding;
  padding-right: vars.$panelRightItemsPadding;
  position: absolute;
  top: 0;
  width: 100%;
  color: #fff;
  line-height: 1.5;
  font-weight: 500;
}

.panelRightFoot {
  padding-left: vars.$panelRightItemsPadding;
  padding-right: vars.$panelRightItemsPadding;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-weight: 400;
  line-height: 1.5;

  a {
    color: #fff;
  }
}

.video {
  display: block;
  width: 100%;

  video {
    background-image: url('./assets/spinner.gif');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

.recommendations {
  display: block;
  width: 100%;
  height: 100%;
  font-size: vars.$fontSize;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: -moz-scrollbars-vertical;
  scrollbar-face-color: #999;
  scrollbar-shadow-color: #5d5d5d;
  scrollbar-highlight-color: #5d5d5d;
  scrollbar-darkshadow-color: #5d5d5d;
  scrollbar-track-color: #5d5d5d;
  scrollbar-arrow-color: #000;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.5 * vars.$baseLine;
  }

  &::-webkit-scrollbar-track {
    background: #5d5d5d;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #999;
  }
}

.articleText {
  display: block;
}

@include setSizes((
  width: 55 * vars.$baseLine,
  height: 40 * vars.$baseLine,
  panelRightWidth: 12 * vars.$baseLine,
  panelRightHeadFont: vars.$fontSize,
  panelRightFootFont: vars.$fontSizeSmall,
  panelRightHeadHeight: 2.5 * vars.$baseLine,
  panelRightFootHeight: 2.5 * vars.$baseLine
));

@media screen and (min-width: vars.$screenMedium) {
  @include setSizes((
    width: 71 * vars.$baseLine,
    height: 40 * vars.$baseLine,
    panelRightWidth: 24 * vars.$baseLine,
    panelRightHeadFont: vars.$fontSizeExtraLarge,
    panelRightFootFont: vars.$fontSize,
    panelRightHeadHeight: 2.5 * vars.$baseLine,
    panelRightFootHeight: 4 * vars.$baseLine
  ));
}

@media screen and (min-width: vars.$screenLarge) {
  @include setSizes((
    width: 90 * vars.$baseLine,
    height: 40 * vars.$baseLine,
    panelRightWidth: 25 * vars.$baseLine,
    panelRightHeadFont: vars.$fontSizeExtraLarge,
    panelRightFootFont: vars.$fontSize,
    panelRightHeadHeight: 2.5 * vars.$baseLine,
    panelRightFootHeight: 4 * vars.$baseLine
  ));
}

@media screen and (max-height: vars.$screenHeightSmall) {
  @include setSizes((
    width: 50 * vars.$baseLine,
    height: 25 * vars.$baseLine,
    panelRightWidth: 24 * vars.$baseLine,
    panelRightHeadFont: vars.$baseLine,
    panelRightFootFont: vars.$baseLine,
    panelRightHeadHeight: 3 * vars.$baseLine,
    panelRightFootHeight: 3 * vars.$baseLine
  ));
}
