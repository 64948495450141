$nav-footer-apps-btn-font-size: 13px !default;
$nav-footer-apps-btn-width: 152px !default;
$nav-footer-apps-btn-height: 40px !default;
$nav-footer-apps-btn-bg-colour: #000 !default;
$sprite: "./appIcons.png";

:global {
  .mol-app-btn {
    display: block;
    box-sizing: border-box;
    position: relative;
    margin: 0;
    padding: 4px 10px;
    border-radius: 2px;
    font-size: $nav-footer-apps-btn-font-size;
    font-weight: normal;
    line-height: 16px;
    color: #fff;
    background-color: $nav-footer-apps-btn-bg-colour;
    text-decoration: none;
    width: $nav-footer-apps-btn-width;
    height: $nav-footer-apps-btn-height;

    &:hover {
      opacity: 0.8;
    }

    &::before {
      content: "";
      float: left;
      margin: 4px 10px 4px 0;
      width: 20px;
      height: 24px;
      background: url($sprite) no-repeat -20px 0;
    }

    &.iphone::before {
      width: 13px;
      background: url($sprite) no-repeat 0 0;
    }
  }
}
