.floater {
  font-family: var(--mol-default-font-family);
  position: fixed;
  top: 30%;
  width: 100%;
  display: flex;
  align-content: center;
  z-index: 99999999;

  .container {
    position: relative;
    margin: auto;
  }

  .mainBox {
    margin: 10px;
    background-color: #fff;
    border: solid 10px #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
  }

  .text {
    font-size: 24px;
    font-weight: 600px;
    line-height: 1.25;
    margin: 0;
  }

  .closeButton {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
  }

  .closeIcon {
    width: 100%;
    height: 100%;
  }

  .success,
  .alert {
    &.mainBox {
      &::before {
        display: block;
        content: "";
        width: 36px;
        height: 36px;
        background-size: 36px 36px;
        margin-right: 18px;
        flex-shrink: 0;
      }
    }
  }

  .success.mainBox::before {
    background-image: url("./assets/success.svg");
  }

  .alert.mainBox::before {
    background-image: url("./assets/alert.svg");
  }
}

@media screen and (max-width: 600px) {
  .floater {
    .text {
      font-size: 16px;
    }
  }
}
