:global {
  .big-picture-carousel {
    position: relative;
    height: 462px;

    a {
      outline: none;
      text-decoration: none;

      &:hover {
        outline: none;
        text-decoration: none;
      }
    }

    .picture-medium {
      float: left;

      img {
        margin: 5px;
      }
    }

    .carouselcontent {
      position: absolute;
      left: 5px;
      bottom: 5px;
      width: 482px;
      font-size: 1.58em;
      font-weight: bold;
    }

    .carouseltext {
      display: block;
      padding: 5px;
      font-size: 0.7em;
      font-weight: normal;
    }

    .carouselstrapline {
      display: block;
      padding: 6px;
      opacity: 0.9;
      filter: alpha(opacity = 90);
      margin: 0 0 5px;
      font-size: 1.2em;
    }

    .carousel {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 139px;

      .scroller {
        float: right;
        margin: 21px 0;
        height: 409px;
      }

      .itemlist {
        margin-top: -138px !important;

        li {
          width: 139px;
          height: 133px;
          margin-bottom: 5px !important;

          span {
            left: 0;
            bottom: 0;
            width: 131px;
            font-size: 1.1em;
            font-weight: bold;
          }
        }
      }

      .paging-controls {
        position: static;

        a {
          width: 139px;
          height: 13px;
          background-image: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_vt_sprite.png");

          &.next {
            bottom: 0;
            background-position: 0 -50px;
          }

          &.previous {
            top: 0;
          }
        }
      }
    }
  }

  /* carousel */

  .big-picture-carousel-grey-ccob .carousel .paging-controls a {
    &.previous {
      background-position: 0 -200px;
    }

    &.next {
      background-position: 0 -250px;
    }
  }

  .big-picture-carousel-grey-wocc .carousel .paging-controls a {
    &.previous {
      background-position: 0 -100px;
    }

    &.next {
      background-position: 0 -150px;
    }
  }
}
