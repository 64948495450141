:global {
  .image-swap-carousel {
    .carousel {
      padding: 2px 2px 4px;
    }

    h3 {
      margin-bottom: 2px;
      font-weight: bold;
    }

    .gallery-frame {
      position: relative;
      margin-bottom: 4px;
      height: 322px;

      a {
        display: block;
      }

      span {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 294px;
        padding: 4px;
        font-size: 1.83em;
        font-weight: bold;
        opacity: 0.7;
        filter: alpha(opacity = 70);
      }
    }

    .scroller {
      width: 265px;
    }

    .itemlist {
      margin-left: -91px !important;

      li {
        margin-right: 8px !important;
        width: 83px;
        height: 74px;
      }

      a {
        height: 74px;
      }

      li span {
        left: 0;
        bottom: 0;
        padding: 0;
        width: 83px;
        height: 14px;
        overflow: hidden;
        text-align: center;
      }
    }

    .paging-controls {
      margin: 0 2px;

      a {
        width: 10px;
        height: 74px;
        top: -74px;

        &.next {
          background-position: -50px 0;
        }
      }
    }
  }

  /* carousel */
}
