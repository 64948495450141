:global {
  .floating-buttons {
    position: fixed;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 9px;
    width: 141px;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
    z-index: 9999;
    display: none;
    flex-flow: row wrap;
    justify-content: space-between;

    .hidden {
      display: none;
    }

    &.floating-no-ads {
      top: 10px;
      right: 10px;
      bottom: auto;
    }

    a {
      width: 60px;
      height: 60px;
      margin-bottom: 1px;
      font: 14px var(--mol-default-font-family);
      text-align: center;
      vertical-align: middle;

      &:hover {
        opacity: 0.85;
      }
    }

    .back-to-home span,
    .back-to-top span {
      position: relative;
      top: 34px;
      text-align: center;
      color: #000;
    }

    .back-to-home {
      background: #004db3 url('./assets/backToHome.png') no-repeat 21px 12px;

      &.new-articles {
        font: 9px var(--mol-default-font-family);
      }

      span {
        color: #fff;
      }
    }

    .back-to-top {
      background: #6cf7ff url('./assets/backToTop.png') no-repeat 18px 12px;
    }

    .button-share-facebook,
    .button-share-twitter {
      cursor: pointer;
      width: 121px;
      height: 60px;
      display: none;
      overflow: hidden;

      span {
        color: #fff;
        font: 18px/60px var(--mol-default-font-family);
        margin: 0 10px 0 50px;
        text-align: center;
        display: block;
        -moz-font-smoothing: antialiased;
        -webkit-font-smoothing: antialiased;
      }
    }

    .button-share-facebook {
      height: 42px;
      background: url('./assets/facebookLong.svg') no-repeat;
      background-size: contain;
      margin-top: 4px;

      span {
        display: none;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .button-share-twitter {
      background: #00aced url('./assets/twitterLogo.svg') no-repeat 18px 19px;

      &:hover {
        background-color: #26b8f0;
      }
    }

    &.has-back-to-mol {
      width: 105px;

      a {
        width: 42px;
        height: 42px;
        font-size: 10px;
      }

      .back-to-mol-button {
        width: 85px;
        height: 31px;
        background: url('./assets/backToMol.png') no-repeat;
        background-size: 84px 31px;
        margin-bottom: 2px;

        &.back-to-mol-dm {
          background-image: url('./assets/backToDM.png');
          background-size: 85px 31px;
        }
      }

      .back-to-home span,
      .back-to-top span {
        top: 26px;
      }

      .back-to-home {
        background-color: #4c0a41;
        background-position: center top 8px;

        &.new-articles span {
          font-size: 7px;
        }
      }

      .back-to-top {
        background-position: center top 10px;
      }

      .button-share-facebook {
        height: 30px;
        width: 85px;

        span {
          font: 13px/44px var(--mol-default-font-family);
          margin: 0 10px 0 40px;
        }
      }

      .button-share-twitter {
        background-position: center left 12px;
        height: 42px;
        width: 85px;
        background-size: 19px 15px;

        span {
          font: 13px/44px var(--mol-default-font-family);
          margin: 0 10px 0 40px;
        }
      }

      .new-article-count {
        width: 18px;
        height: 18px;
        font-size: 9px;
        line-height: 18px;
        top: 46px;
        left: 32px;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -15px;
      left: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      z-index: -1;
    }
  }

  .floating-buttons-show .floating-buttons {
    display: flex;
  }

  .new-article-count {
    position: absolute;
    border-radius: 50px;
    height: 24px;
    width: 24px;
    z-index: 10;
    top: 13px;
    left: 43px;
    background: #ef2320;
    font: 12px var(--mol-default-font-family);
    color: #fff;
    text-align: center;
    line-height: 24px;
  }

  .floating-buttons-back-to-top-only .floating-buttons .back-to-home {
    display: none;
  }

  .no-article-count .new-article-count {
    display: none;
  }

  .floating-buttons-back-to-top-only .floating-buttons {
    width: 81px;

    &.has-back-to-mol {
      width: 105px;

      .back-to-top {
        width: 100%;
      }
    }
  }

  body:not(.floating-buttons-back-to-top-only) .floating-buttons.has-button-share .button-share-facebook,
  body:not(.floating-buttons-back-to-top-only) .floating-buttons.has-button-share .button-share-twitter {
    display: block;
  }

  html:not(.molads_chromeless_on) .floating-buttons:not(.floating-no-ads) {
    bottom: 10px;
    right: 10px;
    left: auto;
    top: auto;
  }

  html.molads_chromeless_on .floating-buttons:not(.floating-no-ads),
  html:not(.molads_chromeless_on) .floating-buttons.has-perform-player {
    top: 10px;
    right: 10px;
    left: auto;
    bottom: auto;
  }

  html.molads_chromeless_on.molads_leader_top_on .floating-buttons:not(.floating-no-ads),
  html.molads_leader_top_on .floating-buttons.has-perform-player {
    top: 130px;
    right: 10px;
    left: auto;
    bottom: auto;
  }
}
