.container {
  display: inline-flex;
  padding: 0;
  cursor: pointer;

  input {
    appearance: none;
    display: none;
  }

  label {
    padding: 8px 12px;
    color: var(--color-black);
    font-size: 0.813rem;
    font-weight: 600;
    line-height: 18px;
    border-radius: 8px;
    background: var(--color-lightgray);
    white-space: nowrap;
    cursor: pointer;
  }

  input:checked + label {
    background: #333;
    color: var(--color-white);
  }

  &:hover {
    opacity: 0.8;
  }
}
