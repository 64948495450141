.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 1rem 6px;
  border-top: 1px solid var(--border-color);

  .reportFlag {
    position: absolute;
    right: 11px;
    top: 5px;
    background: url('../../assets/reportFlag.svg') no-repeat center;
    width: 24px;
    height: 24px;
    border: none;
    flex-shrink: 0;
    visibility: hidden;
  }

  &:hover .reportFlag {
    visibility: visible;
  }
}

.commentFooter {
  display: flex;
  justify-content: space-between;
}
