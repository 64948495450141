@use "../../styles/variables" as vars;

.container {
  padding: 1.75 * vars.$baseLine;
  background: #fff;
  border: 0.625 * vars.$baseLine solid #e3e3e3;
  display: block;
  text-align: center;
}

.message {
  font-size: vars.$fontSize;
  line-height: 1.5;
}
