.promo {
  position: fixed;
  top: 0;
  width: 310px;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 60px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-family: var(--mol-default-font-family);
  color: #000;
  display: flex;
  flex-direction: column;
  z-index: 999999;

  &::after {
    position: absolute;
    display: block;
    content: "";
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background: url("./assets/logo.png");
  }
}

.heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
}

.subHeading {
  font-size: 13px;
  line-height: 1.3;
  width: 100%;
  margin-top: 4px;
  box-sizing: border-box;
  padding-right: 20px;
}

.button {
  width: 170px;
  height: 30px;
  background-color: #e01c44;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-top: 10px;

  &:hover {
    background-color: #ca193d;
  }
}

.close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  background-image: url("./assets/close.svg");
  background-position: center;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}
