:global {
  .reg-flav-dailymail,
  .reg-flav-thisismoney {
    .reg-login-mailplus-customer-services-link {
      display: block;
      background: #004db3;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding: 12px 0;
      text-decoration: none;
      margin: 20px 30px;
      border-radius: 2px;
      text-align: center;
      max-width: 100%;

      &:hover {
        background: #2668be;
        color: #fff;
      }
    }
  }

  .mol-desktop {
    .reg-flav-dailymail,
    .reg-flav-thisismoney {
      .reg-login-mailplus-customer-services-link {
        width: 270px;
        margin-left: 32px;
        margin-right: 0;
        font-size: 15px;
      }
    }
  }

  .nav-footer-mailplus-customer-services-link {
    margin-left: 0.3em;
  }
}
