.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 9px;
  width: 15rem;
  height: 2.875rem;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 #00000029;
  font-family: Inter, sans-serif;
}

.title {
  font-size: 0.875rem;
  font-weight: 700;
}

.status {
  font-size: 0.625rem;
  font-weight: 700;
  color: var(--mol-fe-paywall-profile-product-text);
  padding: 0 10px;
  height: 1.125rem;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  padding-right: 24px;
  border-radius: 10px;
  background-size: 8px 8px;
  background-position: right 10px center;
  background-repeat: no-repeat;
}

.statusActive {
  background-color: var(--mol-fe-paywall-profile-product-active);
  background-image: url("./assets/active.svg");
}

.statusInactive {
  background-color: var(--mol-fe-paywall-profile-product-inactive);
  background-image: url("./assets/inactive.svg");
}
